import React, {useState} from 'react';

import {
  EncapsulatedInput,
  VStack,
  EmbeddedSpekPopover,
  DSButton as Button,
  Heading,
  Icon,
  HStack,
  Divider,
  ActionWithConfirmation,
  Link,
  DSTooltip as Tooltip,
  Box,
  useToast,
  PrivacySettings,
  Flex,
} from 'spekit-ui';
import {RiQuestionLine, RiCloseLine, RiAddCircleLine} from 'react-icons/ri';
import {
  CKE_CONTENT_SIZE_ERROR,
  CONTENT_SHARING,
  getSearchParam,
  logging,
  topics as topicsAPI,
  utils,
} from 'spekit-datalayer';
import {
  ISpekFromState,
  TClonedTerm,
  ICreateSpekResponse,
  TCreateSpekPayload,
  IExtendedNotificationState,
  IOptionType,
} from 'spekit-types';
import {EditingView} from '../../RichTextEditor/components/EditingView';
import {useFormContext, Controller} from 'react-hook-form';
import {TopicPicker} from '../TopicPicker';
import {ExpertPicker} from '../ExpertPicker';
import {spekAPI} from 'spekit-datalayer';
import {SalesforceObjectPicker} from '../SalesforceObjectPicker';
import {useAI} from '../../hooks/useAI';

import {ReactComponent as SmartTitleIcon} from '../../spekitAI/ai_sparkle.svg';
import {ReactComponent as SmartTitleIconAnim} from '../../spekitAI/ai_sparkle_anim.svg';
import {CustomFieldsEditor} from '../../Content/CustomFieldsEditor/CustomFieldsEditor';
import {NotificationButton} from '../../Notifications/NotificationButton';

type Common = {
  hasSpekitAI?: boolean;
  spekID?: string;
  placeholder?: string;
  host?: string;
};
export type TEditSpekForm = Common & {
  mode: 'edit';
};

export type TCreateSpekForm = Common & {
  mode: 'create' | 'clone';
  onClose: () => void;
  openSpotlightModal: (props: any) => void;
  termToClone?: TClonedTerm;
  onSave?: (
    state: ICreateSpekResponse,
    createSpotlight: boolean,
    notificationSettings: {
      sendNotification: boolean;
      sendEmail: boolean;
      message: string;
      teams: IOptionType[];
    }
  ) => void | Promise<void>;
};

export type SpekFormProps = TCreateSpekForm | TEditSpekForm;

export function SpekForm(props: SpekFormProps) {
  const {placeholder = '', host, hasSpekitAI, spekID, mode} = props;
  const isCreateOrClone = mode === 'clone' || mode === 'create';
  // if we are cloning a term, we need to populate the form with the values of the term
  const {setValue, control, watch, setError, getValues, handleSubmit} =
    useFormContext<ISpekFromState>();

  // uploading state to disable the submit button
  const [isUploading, setIsUploading] = useState(false);
  const [isFormProcessing, setIsFormProcessing] = useState(false);

  const {generateTitle, processing} = useAI();

  // toast for messages
  const toast = useToast();

  const getNewTitle = async (content: string) => {
    const response = await generateTitle(content, spekID || '');
    if (response) {
      setValue('title', response);
    } else {
      toast({
        variant: 'error',
        description: 'Request unsuccessful. Please try again.',
      });
    }
  };

  // watch for changes to the notification checkbox and topics
  const [tags, customFields, definition, type] = watch([
    'topics',
    'customFields',
    'definition',
    'type',
  ]);

  React.useEffect(() => {
    if (mode === 'clone' || mode === 'edit') return;
    async function initializeTopic() {
      const search = await getSearchParam();
      const {topic: topicId, tag: topicName} = utils.parseQs(search) as Partial<{
        topic: string;
        tag: string;
      }>;
      const topics = await topicsAPI.get(topicName, {allowedOnly: true});
      const topic = topics.find((topic) => topic.value === topicId);
      if (topic) setValue('topics', [topic]);
    }
    initializeTopic();
  }, [setValue, mode]);

  // save the form state to the database
  // open create spotlight modal if the user has selected that option
  const onCreate = async (notification: IExtendedNotificationState) => {
    if (props.mode === 'edit') return;
    const {onSave, onClose, openSpotlightModal} = props;
    setIsFormProcessing(true);
    const {notifyByEmail, sendNotification, message, createSpotlight, teams} =
      notification;
    const state = getValues();
    try {
      const spekPayload: TCreateSpekPayload = {
        ...state,
        notification,
      };
      const response = await spekAPI.createSpek(spekPayload);
      const toastMessage = sendNotification
        ? 'Spek created and users notified!'
        : 'Spek created!';
      if (response) {
        // onSave is a function passed by the webapp or extension to handle the response.
        // Each environment has unique needs
        if (onSave)
          await onSave(response, createSpotlight, {
            sendNotification,
            sendEmail: notifyByEmail,
            message,
            teams,
          });
        //@TODO add tracking
        toast({description: toastMessage, variant: 'success'});
        // webapp only - no host
        if (createSpotlight && !host) {
          openSpotlightModal({
            isSpotlightSelected: true,
            businessTerm: response.business_term,
            message,
            teams,
          });
        }
        onClose();
      }
    } catch (e) {
      logging.capture(e);
      if (e.message === 'Payload too large') {
        setError('definition', {
          type: 'manual',
          message: CKE_CONTENT_SIZE_ERROR,
        });
      }
    } finally {
      setIsFormProcessing(false);
    }
  };

  return (
    <Flex
      // height hack for consistent scrolling experience in extension iframe
      height={host && isCreateOrClone ? '100vh' : 'auto'}
      minH={0}
      direction='column'
    >
      {/* design requirements require some hacky padding here */}
      {isCreateOrClone && (
        <HStack
          justifyContent='space-between'
          alignItems='center'
          boxSizing={'border-box'}
          marginLeft={'-1px'}
          // required for differences in extension rendering
          // temporary until modal is phased out
          pl={host ? '4px' : '0px'}
          width={host ? 'calc(100% - 4px)' : 'calc(100% + 12px)'}
        >
          <Heading fontSize='large' fontWeight='semibold'>
            Create Spek
          </Heading>
          <HStack>
            <Tooltip label='Help center' placement='bottom' shouldWrapChildren>
              <Button
                as={Link}
                icon={<Icon h='1.2rem' w='1.2rem' as={RiQuestionLine} />}
                aria-label='Help Center Link'
                colorScheme='white'
                variant='icon'
                size='medium'
                isExternal
                href='https://help.spekit.com/hc/en-us/articles/9922580438299-How-do-I-create-Speks-'
              />
            </Tooltip>
            <NotificationButton
              topics={tags.map((t) => t.value)}
              mode='create'
              handleSubmit={handleSubmit}
              onSave={onCreate}
              isSubmitting={isFormProcessing}
              disabled={isUploading || isFormProcessing}
              testId='create-spek-button'
            />
            <Divider orientation='vertical' h='2rem' />
            <ActionWithConfirmation
              icon={RiCloseLine}
              confirmationHeader='Close this Spek'
              confirmationMessage='Are you sure? Closing this Spek will cause your progress to be deleted.'
              confirmActionText='Yes, close'
              confirmAction={props.onClose}
              actionTooltip='Close'
            />
          </HStack>
        </HStack>
      )}
      {/* the margins and scrollbar are here to match with the extension as we prepare to migrate from the modal */}
      <Box
        id='spek-creation-modal'
        data-testid='spekCreation-modal'
        overflowY={'auto'}
        mt={isCreateOrClone ? 10 : undefined}
        pr={isCreateOrClone ? 8 : undefined}
        pl={4}
        boxSizing='border-box'
        // required for differences in extension rendering
        // will be removed when modal is phased out
        marginRight={host ? 0 : '-16px'}
        marginLeft={host ? 0 : '-4px'}
        sx={{
          // match scrollbar to extension
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#d8dbe0',
            borderRadius: '2px',
          },
        }}
      >
        <VStack alignItems='start' width='100%' pb={3} data-testid='spekCreation-modal'>
          <Controller
            name='title'
            control={control}
            render={({field, fieldState}) => (
              <EncapsulatedInput
                {...field}
                testId='spek-title-input'
                label='Spek title'
                isRequired
                placeholder='Enter Spek title'
                helpText={<EmbeddedSpekPopover />}
                errorMessage={fieldState.error?.message}
                isInvalid={!!fieldState.error}
                isDisabled={processing || type !== 'business_term'}
                inputControl={
                  hasSpekitAI && (
                    <Tooltip label='Smart Title' placement='top' shouldWrapChildren>
                      <Button
                        icon={
                          <Icon
                            h='1.2rem'
                            w='1.2rem'
                            as={processing ? SmartTitleIconAnim : SmartTitleIcon}
                          />
                        }
                        aria-label='Smart Title Button'
                        data-testid='smart-title-button'
                        colorScheme='outlined'
                        variant='icon'
                        size='medium'
                        onClick={() => getNewTitle(getValues('definition'))}
                        isDisabled={
                          processing || definition === '' || type !== 'business_term'
                        }
                      />
                    </Tooltip>
                  )
                }
              />
            )}
          />
          <Controller
            name='definition'
            control={control}
            render={({field, fieldState}) => (
              <EditingView
                {...field}
                placeholder={placeholder}
                host={host}
                setUploadBusy={() => setIsUploading(true)}
                unSetUploadBusy={() => setIsUploading(false)}
                enhanced={hasSpekitAI}
                spekID={spekID}
                isError={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              />
            )}
          />
          <VStack alignItems='start' width='100%'>
            <HStack alignItems='start' width='100%'>
              {/* all of the inputs have a matching width, but the topic picker has a button to the right.
              we wrap all the inputs to give them consistent width while allowing space for a button */}
              <Box maxW='500px' width='100%'>
                <TopicPicker type={type} />
              </Box>
              <Button
                href={`${host || ''}/app/wiki/topics/create`}
                as={Link}
                colorScheme='primary'
                leftIcon={<Icon h='18px' w='18px' as={RiAddCircleLine} />}
                size='large'
                variant='ghost'
                target='_blank'
                // this margin aligns the button with the topic picker
                mt={'33px !important'}
              >
                Create Topic
              </Button>
            </HStack>
            <Box maxW='500px' width='100%'>
              <ExpertPicker tags={tags.map((t) => t.label)} />
            </Box>
            {type === 'business_term' && (
              <Box maxW='500px' width='100%'>
                <SalesforceObjectPicker />
              </Box>
            )}
            <Box w='500px'>
              <Box mt={16}>
                <CustomFieldsEditor
                  type={type}
                  updateValues={(customFields) => setValue('customFields', customFields)}
                  values={customFields}
                  mode={mode}
                />
              </Box>
            </Box>
            <Controller
              name='shareable'
              control={control}
              render={({field}) => (
                <Box mt='24px !important'>
                  <PrivacySettings
                    data-testid='spek-external-share-control'
                    isChecked={field.value}
                    onChange={field.onChange}
                    alertMessage='Existing external links for this content will no longer be accessible.'
                  >
                    {CONTENT_SHARING.ALL_USERS}
                  </PrivacySettings>
                </Box>
              )}
            />
          </VStack>
        </VStack>
      </Box>
    </Flex>
  );
}
