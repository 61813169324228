import {useRef} from 'react';
import {useFormContext} from 'react-hook-form';
import {RiUpload2Line} from 'react-icons/ri';
import {ICustomBrandingFormState, TCustomBrandingFromStateImageKeys} from 'spekit-types';
import {useToast, DSButton as Button, Icon, Flex} from 'spekit-ui';
import {ERROR_MESSAGES} from './constants';
import {schema} from './form';

interface IUploadButton {
  setFile: (file: File | null) => void;
  label: 'Logotype' | 'Logomark';
  testId: string;
  fieldName: TCustomBrandingFromStateImageKeys;
}

const tooltipLabelMap = {
  Logotype: 'Upload logotype',
  Logomark: 'Upload logomark',
} as const;

export const UploadButton = ({setFile, label, testId, fieldName}: IUploadButton) => {
  const {setError} = useFormContext<ICustomBrandingFormState>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const toast = useToast();
  return (
    <Flex alignItems='center'>
      <Button
        variant='icon'
        colorScheme='white'
        aria-label={`Upload ${label}`}
        icon={<Icon as={RiUpload2Line} h={4} w={4} />}
        size='medium'
        h='32px'
        w='34px'
        onClick={() => fileInputRef.current?.click()}
        tooltipLabel={tooltipLabelMap[label]}
      />
      <input
        data-testid={testId}
        ref={fileInputRef}
        style={{visibility: 'hidden', height: 0, width: 0}}
        type='file'
        accept='.png, .jpg, .jpeg, .svg'
        onChange={async (event) => {
          const file = event.target.files?.[0];
          // cleans the value so user can reselect the same image
          event.target.value = '';
          const isValid = await schema.fields[fieldName].isValid(file);
          if (file && isValid) {
            setFile(file);
            toast({variant: 'success', description: `${label} uploaded!`});
          } else {
            setError(fieldName, {
              message: ERROR_MESSAGES.supportedFormat,
              type: 'fileType',
            });
          }
        }}
      />
    </Flex>
  );
};
