import {ILoginResponse, ILogInstallResponse} from 'spekit-types';
import {create} from '../utils/APIHelpers';

export const validateUsernameEmail = async (
  username: string,
  redir: string
): Promise<ILoginResponse> => {
  const response: ILoginResponse = await create(
    `/user-management/v1/login-route/${redir}`,
    {login_hint: username}
  );
  return response;
};

export const logInstall = async (
  application: 'chrome' | 'outlook' | 'slack'
): Promise<ILogInstallResponse> => {
  const body = {
    type: 'user_progress',
    data: {
      [`${application}_installed`]: true,
    },
  };

  return await create(`/api/v1/events/`, body);
};
