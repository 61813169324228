/**
 * The ReadOnlyView component is used to display speks in read-only mode.
 * It uses the html-react-parser library to parse raw CKEditor output (an html string)
 * The parser replaces embedded content and images with components that extend their functionality.
 */

import React, {useCallback, useEffect, useRef, useState, RefObject} from 'react';

// import html-react-parser library
import parse, {
  HTMLReactParserOptions,
  Element,
  attributesToProps,
  domToReact,
} from 'html-react-parser';

// import components
import classNames from 'classnames';
import {EmbedCard} from './EmbedCard';
import {IFramer, IFramerProps} from './IFramer';
import {Embiggener} from './Embiggener';
import {Box} from 'spekit-ui';
import {SimpleAnchor} from './SimpleAnchor';
import {Mention} from './Mention';

// utils

import '../styles/editorStyles.css';
import {getImageSource} from '../plugins/util';
import {utils} from 'spekit-datalayer';
import {retrieveContentDataFromUrl} from 'spekit-datalayer/src/utils/commonUtils';
import {TAssetMimeType} from 'spekit-types';

// define props
export interface ReadOnlyViewProps {
  singleLine?: boolean;
  doubleLine?: boolean;
  host?: string | null;
  value: string;
  onImageZoom?: (url: string) => void;
  isPreview?: boolean;
  openInSidebar?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    href: string
  ) => void;
  isFromSearch?: boolean;
  disableLinkTab?: boolean;
  onHeightChange?: (height: number) => void;
}

export interface ParserElement extends Element {
  data: string;
  nodeValue: () => any;
}

export const isHiddenInPreview = (element: ParserElement) => {
  // hide embedded content
  // We only have 2 custom schemas now (files and iframes) and each has a different data attribute
  // We may need to engineer a more scalable solution if our amount of embedded content grows
  if (element.attribs && (element.attribs['data-file'] || element.attribs['data-src'])) {
    return true;
  }

  // no images in previews
  if (element.name === 'img') {
    return true;
  }

  // no code blocks in previews
  if (element.name === 'pre') {
    return true;
  }

  // no tables in previews
  if (element.name === 'table') {
    return true;
  }

  return false;
};

export const ReadOnlyView: React.FC<ReadOnlyViewProps> = ({
  singleLine,
  doubleLine,
  host,
  value,
  onImageZoom,
  isPreview,
  openInSidebar,
  isFromSearch,
  disableLinkTab,
  onHeightChange,
}) => {
  const containerRef: RefObject<HTMLElement> = useRef<HTMLElement>(null);
  const [height, setHeight] = useState(0);

  // @ts-ignore
  useEffect(() => {
    if (onHeightChange && containerRef?.current) {
      const currentRef = containerRef.current;
      const newHeight = currentRef.offsetHeight;
      if (newHeight !== undefined && newHeight !== height) {
        setHeight(newHeight);
        onHeightChange(newHeight);
      }
    }
  }, [onHeightChange]);

  const [parsedElements, setParsedElements] = React.useState<
    string | JSX.Element | JSX.Element[]
  >([]);
  /**
   * Function that uses the html-react-parser library to parse the raw CKEditor output (an html string)
   * https://github.com/remarkablemark/html-react-parser#readme
   * The parser replaces embedded content and images with components that extend their functionality.
   * As we add more embedded content types, we will use this function to insert the appropriate component.
   */
  const parseHtml = useCallback(() => {
    // define options for html parser
    const htmlParseOptions: HTMLReactParserOptions = {
      // TODO: abstract this replace function into separate function for easier testing, maintenance and scaling
      replace: (domNode) => {
        const element = domNode as ParserElement;
        const tabIndex = disableLinkTab ? -1 : undefined;

        // hide disallowed elements in preview
        if ((singleLine || isPreview) && isHiddenInPreview(element)) {
          return <></>;
        }

        // handle links
        if (element.name === 'a') {
          if (element.attribs['data-mention']) {
            const label = element.attribs['data-label'];
            const href = element.attribs.href;
            const contentType = element.attribs['data-content-type'] as TAssetMimeType;
            const {type} = retrieveContentDataFromUrl(href);
            return (
              <Mention
                link={href}
                openInSidebar={openInSidebar}
                label={label}
                type={type}
                tabIndex={tabIndex}
                contentType={contentType}
              />
            );
          }

          return (
            <SimpleAnchor
              openInSidebar={openInSidebar}
              href={element.attribs.href}
              className={element.attribs.class}
              tabIndex={tabIndex}
            >
              {domToReact(element.children)}
            </SimpleAnchor>
          );
        }

        // replace embedded content with components
        // all custom schemas parse down to section tags, so we check for our custom data attributes
        if (
          element.attribs &&
          (element.attribs['data-file'] || element.attribs['data-src'])
        ) {
          if (
            element.attribs['data-src'] &&
            element.attribs['data-src'] !== 'undefined'
          ) {
            // parse options from data attribute and define type
            // fallback to empty object if no options are provided
            const options = JSON.parse(
              element.attribs['data-options'] || '{}'
            ) as IFramerProps['options'];
            return <IFramer src={element.attribs['data-src']} options={options} />;
          }

          if (
            element.attribs['data-file'] &&
            element.attribs['data-file'] !== 'undefined'
          ) {
            return (
              <EmbedCard
                enabled={true}
                host={host}
                file={JSON.parse(element.attribs['data-file'])}
                tabIndex={tabIndex}
              />
            );
          }
        }

        // replace images with Embiggener component
        if (element.name === 'img' && element.attribs && element.attribs['src']) {
          // get image attributes and src
          const imgAttribs = attributesToProps(element.attribs);
          const imgSrc = getImageSource(imgAttribs.src, host);

          return (
            <Embiggener
              imgAttribs={imgAttribs}
              imgSrc={imgSrc}
              onImageZoom={onImageZoom}
            />
          );
        }

        // return the element if no replacements match
        return element;
      },
    };

    // parse and return the html using the options
    return parse(value || '', htmlParseOptions);
  }, [value, singleLine, isPreview, openInSidebar, host, onImageZoom, disableLinkTab]);

  useEffect(() => {
    setParsedElements(parseHtml());
  }, [parseHtml]);

  // Checks if you are searching from the webapp or the extension
  const hasSearchedInWebapp = isFromSearch && !utils.isChromeExtension();
  const hasSearchedInExtension = isFromSearch && utils.isChromeExtension();

  // assign css classes
  const classes = classNames('spekit-ck-content', 'ck-content', 'ck-read-only', {
    'ck-preview': singleLine || isPreview,
    'search-preview-webapp': hasSearchedInWebapp,
    'search-preview-extension': hasSearchedInExtension || doubleLine,
    'ck-chrome-extension': utils.isChromeExtension(),
  });

  return (
    // wrap the spek in a Box to contain overflow in previews
    <Box
      maxHeight={singleLine ? '30px' : 'auto'}
      overflow={singleLine ? 'hidden' : 'unset'}
      maxW='100%'
      ref={containerRef}
    >
      {/* plain div gets ck editor classes applied to receive default and overridden content styles */}
      <div className={classes} id='ck-content'>
        {parsedElements}
      </div>
    </Box>
  );
};
