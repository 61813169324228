import {
  Select,
  Flex,
  Input,
  Card,
  Text,
  DSButton as Button,
  useToast,
  Box,
  Heading,
  DSModal as Modal,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModernPagination,
  FormLabel,
  FormControl,
  Link,
} from 'spekit-ui';
import {useState, useEffect} from 'react';
import {IApiToken} from 'spekit-types';
import {
  apitokengeneration,
  logging,
  copyToClipboard,
  parseISO,
  SHORTENED_DATE_FORMAT,
  format,
} from 'spekit-datalayer';
import {Redirect} from 'react-router-dom';
import {hasSomeOf} from '../../utils/permissions';

const PAGINATION_LIMIT = 25;
const EXPIRY_OPTIONS = [
  {label: '1 week', value: '1 week'},
  {label: '2 weeks', value: '2 week'},
  {label: '1 month', value: '1 month'},
  {label: '2 months', value: '2 month'},
  {label: '4 months', value: '4 month'},
  {label: '6 months', value: '6 month'},
  {label: '1 year', value: '1 year'},
  {label: '2 years', value: '2 year'},
  {label: 'Never expire', value: ''},
];

const GenerateAPITokens = () => {
  const [status, setStatus] = useState<IApiToken[]>([]);
  const [expiryVal, setexpiryVal] = useState(EXPIRY_OPTIONS[0]);
  const [descriptionVal, setDescription] = useState('');
  const [recentlyGeneratedToken, setRecentlyGeneratedToken] = useState<IApiToken | null>(
    null
  );

  const [revokeAllModal, setRevokeAllModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);

  const totalPages = Math.ceil(totalNumberOfRecords / PAGINATION_LIMIT);
  const offset = (pageNumber - 1) * PAGINATION_LIMIT;
  const currentTokensCount = Math.min(
    pageNumber * PAGINATION_LIMIT,
    totalNumberOfRecords
  );

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedToken, setSelectedToken] = useState<IApiToken | null>(null);
  const [deleteBusy, setDeleteBusy] = useState(false);
  const formatKey = (key: string) => {
    if (!key) return '';
    return `${key.slice(0, 4)}-*****-***********-************-*******-*********`;
  };

  useEffect(() => {
    const getTokenList = async () => {
      try {
        const response = await apitokengeneration.getTokens({
          page: pageNumber,
        });
        console.log(response.results);
        setStatus(response.results);
        setTotalNumberOfRecords(response.count);
      } catch (e) {
        logging.capture(e as Error);
      }
    };
    getTokenList();
  }, [pageNumber]);

  const saveStep = async () => {
    try {
      const response = await apitokengeneration.postTokenGeneration({
        description: descriptionVal,
        expiry: expiryVal.value,
      });
      setRecentlyGeneratedToken(response);
    } catch (err) {
      logging.capture(err as Error);
    }
  };
  const handleChange = (selectedOption: any) => {
    setexpiryVal(selectedOption);
  };

  const toast = useToast();

  const handleCopy = async () => {
    if (!recentlyGeneratedToken) return;
    copyToClipboard({
      textPlain: recentlyGeneratedToken.key,
      textHtml: recentlyGeneratedToken.key,
    });
    toast({description: 'API token copied to clipboard', variant: 'success'});
  };
  const handlePageChange = (page: number) => {
    setPageNumber(page);
  };

  const revokeToken = async (id: string) => {
    setDeleteBusy(true);
    try {
      const response = await apitokengeneration.deleteToken(id);
      if (response.success) {
        const response_all_token = await apitokengeneration.getTokens({
          page: pageNumber,
        });
        setStatus(response_all_token.results);
        setTotalNumberOfRecords(response_all_token.count);
        toast({description: 'API Token deleted.', variant: 'success'});
        setRecentlyGeneratedToken(null);
      } else {
        toast({description: 'API Token cannot deleted.', variant: 'error'});
      }

      setDeleteModal(false);
    } catch (err) {
      logging.capture(err as Error);
    } finally {
      setDeleteBusy(false);
    }
  };
  const confirmRevokeAll = async () => {
    setDeleteBusy(true);
    try {
      await apitokengeneration.revokeAllTokens();
      const response = await apitokengeneration.getTokens({
        page: pageNumber,
      });
      setStatus(response.results);
      setTotalNumberOfRecords(response.count);
      toast({description: 'All API Tokens revoked.', variant: 'success'});
      setRevokeAllModal(false);
    } catch (err) {
      logging.capture(err as Error);
      toast({description: 'Failed to revoke all tokens.', variant: 'error'});
    } finally {
      setDeleteBusy(false);
    }
  };

  const openDeleteModal = (token: IApiToken) => {
    setSelectedToken(token);
    setDeleteModal(true);
  };

  const confirmDelete = () => {
    if (selectedToken) {
      revokeToken(selectedToken.id);
    }
  };
  if (!hasSomeOf(['customcolumns.manage_custom_columns'])) {
    return <Redirect to='/notAllowed' />;
  }

  return (
    <Flex direction='column' pt={32} gap={32} h='100%' overflow='hidden'>
      <Flex direction='column' gap={12}>
        <Card p={24} pb={30}>
          <Flex direction='column' gap={16}>
            <Heading fontSize='massive' fontWeight={600}>
              API tokens
            </Heading>
            <Text variant='body2' fontWeight={400}>
              API tokens grant authorized access to the{' '}
              <Link href='https://api.spekit.co/docs/' color='primary.500' isExternal>
                Spekit API
              </Link>
              .
            </Text>

            <Flex justifyContent='space-between' alignItems='flex-end'>
              <Flex direction='column' alignItems='flex-start' gap={8}>
                <FormControl>
                  <FormLabel>Token active for:</FormLabel>
                  <Flex justifyContent='space-between' alignItems={'center'} gap={8}>
                    <Box width={300}>
                      <Select
                        id='select-expiry'
                        isMulti={false}
                        value={expiryVal}
                        options={EXPIRY_OPTIONS}
                        onChange={handleChange}
                      />
                    </Box>
                    <Input
                      id='add-description'
                      isRequired
                      value={descriptionVal}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder='Enter description'
                      width='300px'
                      mt={4}
                      marginBottom={4}
                      autoComplete='off'
                    />
                  </Flex>
                </FormControl>
              </Flex>

              <Flex alignItems={'center'} mb={10} gap={8}>
                <Button
                  variant='outlined'
                  size='medium'
                  onClick={() => setRevokeAllModal(true)}
                  disabled={status.length === 0}
                >
                  Revoke all tokens
                </Button>
                <Button
                  colorScheme='primary'
                  variant='contained'
                  size='medium'
                  onClick={saveStep}
                >
                  Generate new token
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Card>
      </Flex>
      {recentlyGeneratedToken && (
        <Flex direction='column' gap={12}>
          <Card p={24} pb={30}>
            <Flex direction='column' gap={16}>
              <Text variant='body2' fontWeight={600}>
                New token generated
              </Text>
              <Text variant='body2' fontWeight={400}>
                Make sure to copy your personal access token now. For your security, you
                won’t be able to see it again.
              </Text>
              <Flex justifyContent='space-between' alignItems={'center'}>
                <Flex justifyContent='space-between' alignItems={'center'} gap={8}>
                  <Input
                    w={640}
                    type='text'
                    id='generated-token'
                    value={recentlyGeneratedToken.key}
                    readOnly
                  />
                  <Button
                    w={79}
                    colorScheme='primary'
                    variant='contained'
                    size='large'
                    onClick={handleCopy}
                  >
                    Copy
                  </Button>
                </Flex>

                <Flex alignItems={'center'} gap={16}>
                  <Text variant='caption1' fontWeight={400}>
                    Expires on:{' '}
                    {recentlyGeneratedToken.expiry_dt
                      ? format(
                          parseISO(recentlyGeneratedToken.expiry_dt),
                          SHORTENED_DATE_FORMAT
                        )
                      : 'Never'}
                  </Text>
                  <Button
                    variant='outlined'
                    size='medium'
                    onClick={() => openDeleteModal(recentlyGeneratedToken)}
                  >
                    Revoke
                  </Button>
                </Flex>
              </Flex>
              <Text variant='caption1' fontWeight={200}>
                Personal access tokens function like a combined name and password for API
                authentication.
              </Text>
            </Flex>
          </Card>
        </Flex>
      )}
      <Flex direction='column' gap={12}>
        {status.map((item) => (
          <>
            <Card key={item.key} p={24} pb={30}>
              <Flex direction='column' gap={16}>
                <Text variant='body2' fontWeight={600}>
                  Token created on:{' '}
                  {format(parseISO(item.created_on), SHORTENED_DATE_FORMAT)}
                </Text>
                <Text fontWeight={400} variant='body2'>
                  {item.description}
                </Text>
                <Flex justifyContent='space-between' alignItems={'center'}>
                  <Text fontWeight={400} variant='body2'>
                    Key: {formatKey(item.key)}
                  </Text>
                  <Flex alignItems={'center'} gap={16}>
                    <Text variant='caption1' fontWeight={400}>
                      Expires on:{' '}
                      {item.expiry_dt
                        ? format(parseISO(item.expiry_dt), SHORTENED_DATE_FORMAT)
                        : 'Never'}
                    </Text>
                    <Button
                      variant='outlined'
                      size='medium'
                      onClick={() => openDeleteModal(item)}
                    >
                      Revoke
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </Card>
          </>
        ))}
        <Modal size='sm' isOpen={deleteModal} onClose={() => setDeleteModal(false)}>
          <ModalCloseButton />
          <ModalHeader>
            Are you sure you want to revoke the token starting with{' '}
            {selectedToken?.key.slice(0, 4)}?
          </ModalHeader>

          <ModalBody>
            Any applications or scripts using this token will no longer be able to access
            the Spekit API. You cannot undo this action.
          </ModalBody>

          <ModalFooter>
            <Flex gap={6}>
              <Button
                onClick={() => setDeleteModal(false)} // Close the modal
                colorScheme='white'
                size='medium'
                variant='ghost'
                data-testid='delete-token-cancel-btn'
              >
                Cancel
              </Button>
              <Button
                data-testid='delete-btn'
                size='medium'
                variant='danger'
                onClick={confirmDelete} // Confirm deletion
                isLoading={deleteBusy} // Show loading state while deleting
              >
                I understand, revoke this token
              </Button>
            </Flex>
          </ModalFooter>
        </Modal>

        <Modal size='sm' isOpen={revokeAllModal} onClose={() => setRevokeAllModal(false)}>
          <ModalCloseButton />
          <ModalHeader>Are you sure you want to revoke all tokens?</ModalHeader>

          <ModalBody>
            Any applications or scripts using these tokens will no longer be able to
            access the API. You cannot undo this action.
          </ModalBody>

          <ModalFooter>
            <Flex gap={6}>
              <Button
                onClick={() => setRevokeAllModal(false)} // Close modal
                colorScheme='white'
                size='medium'
                variant='ghost'
              >
                Cancel
              </Button>
              <Button
                size='medium'
                variant='danger'
                onClick={confirmRevokeAll} // Confirm revoke all
                isLoading={deleteBusy} // Show loading state
              >
                I understand, revoke all tokens
              </Button>
            </Flex>
          </ModalFooter>
        </Modal>
      </Flex>
      {totalNumberOfRecords > 25 && (
        <Flex align='center' mt='auto' pb={32}>
          <Text fontSize='small' flex={1}>
            Showing {offset + 1} to {currentTokensCount} of {totalNumberOfRecords} results
          </Text>

          <ModernPagination
            totalPages={totalPages}
            currentPage={pageNumber}
            onChange={handlePageChange}
            dataTestId='pagination'
          />
          <Box flex={1} />
        </Flex>
      )}
    </Flex>
  );
};

export default GenerateAPITokens;
