import React, {useState, useEffect, useCallback} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import LoginLayout from '../../components/LoginLayout';
import {utils} from 'spekit-datalayer';
import {RiArrowLeftLine} from 'react-icons/ri';
import ssoImage from '../../../../images/SSO.svg';
import salesforceImage from '../../../../images/Salesforce.svg';
import {FormProvider} from 'react-hook-form';
import useLogin from '../../hooks/useLogin';
import PasswordInput from '../../components/LoginInputFields/PasswordInput';
import UsernameInput from '../../components/LoginInputFields/UsernameInput';

import {
  Flex,
  Heading,
  Text,
  Icon,
  DSButton as Button,
  Image,
  Loader,
  Fade,
} from 'spekit-ui';
import {getItem} from 'spekit-ui/src/utils/localStorage';

interface IShareParams {
  term_type?: string;
  term_id?: string;
}

const LoginAllOptions = () => {
  const history = useHistory();
  const location = useLocation();
  const {methods, isLoading, isPageLoading, trackUser, loginHandler} = useLogin();
  const [shareParams, setShareParams] = useState<IShareParams | boolean>(false);
  const [parametersToSend, setParametersToSend] = useState<Array<string>>([]);
  const [hasMultipleAccountsFlag, setHasMultipleAccounts] = useState<boolean>(false);
  const [isUsernamePresent, setIsUsernamePresent] = useState<boolean>(false);

  const getShareLink = useCallback(() => {
    try {
      const VAR_SHARE_PARAMS_LENGTH = 5; // Check if share params exist
      const TERM_TYPE_SEGMENT = 3; // Business Term type
      const TERM_ID_SEGMENT = 4; // Business Term ID
      const TERM_TYPES = ['object', 'field', 'field_value', 'business_term'];
      let parameters = utils.parseQs(location.search);
      let redirParameters = (parameters.redir as string).split('?');
      let routeSegments = redirParameters[0].split('/');
      let shareParams = {} as IShareParams;
      if (routeSegments.length === VAR_SHARE_PARAMS_LENGTH) {
        if (TERM_TYPES.indexOf(routeSegments[TERM_TYPE_SEGMENT]) !== -1) {
          shareParams.term_type = routeSegments[TERM_TYPE_SEGMENT];
          shareParams.term_id = routeSegments[TERM_ID_SEGMENT];
          return shareParams;
        }
      }
    } catch (err) {
      return false;
    }
    return false;
  }, [location.search]);

  const getParametersToSend = useCallback(() => {
    let params = utils.parseQs(location.search);
    let parameters = [] as Array<string>;
    if (params.redir) {
      parameters.push('redir=' + encodeURIComponent(params.redir as string));
    }
    if (!!shareParams) {
      parameters.push(`term_type=${shareParams['term_type']}s`);
      parameters.push(`term_id=${shareParams['term_id']}`);
    }
    return parameters;
  }, [shareParams, location.search]);

  const checkMultipleAccounts = useCallback(() => {
    const {hasMultipleAccounts} = (location.state as {hasMultipleAccounts: boolean}) || {
      hasMultipleAccounts: false,
    };
    const {username} = (location.state as {username: string}) || {username: ''};
    setHasMultipleAccounts(hasMultipleAccounts);
    if (hasMultipleAccounts) {
      methods.setValue('username', username);
      methods.setError('username', {
        type: 'multiple-accounts',
        message:
          'This email address is associated with multiple usernames. Try logging in with a username, or click “Forgot username?” and we’ll email you the usernames associated with your Spekit account.',
      });
    } else {
      const localusername = getItem('username');
      if (localusername) {
        methods.setValue('username', localusername);
        setIsUsernamePresent(true);
      }
    }
  }, [location.state, methods]);

  useEffect(() => {
    setShareParams(getShareLink());
    setParametersToSend(getParametersToSend());
    checkMultipleAccounts();
  }, [getShareLink, getParametersToSend, checkMultipleAccounts, methods]);

  const loginWithSalesforce = () => {
    trackUser('Login Beta Flow: Clicked login with Salesforce button', {
      parameters: parametersToSend,
    });
    window.location.href =
      '/api/auth/login/salesforce' +
      (parametersToSend.length ? '?' + parametersToSend.join('&') : '');
  };

  const loginWithSalesforceSandbox = () => {
    trackUser('Login Beta Flow: Clicked login with Salesforce sandbox button', {
      parameters: parametersToSend,
    });
    window.location.href =
      '/api/auth/login/salesforce?sandbox=true' +
      (parametersToSend.length ? '&' + parametersToSend.join('&') : '');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      methods.handleSubmit(loginHandler)();
    }
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (hasMultipleAccountsFlag && e.target.value.indexOf('@') > -1) {
      methods.setError('username', {
        type: 'multiple-accounts',
        message:
          'Enter your username, not your email. Find your username in the first email from Spekit',
      });
      return;
    }
    methods.clearErrors();
    methods.setValue('username', e.target.value);
  };

  const handlePasswordInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    methods.clearErrors();
    methods.setValue('password', e.target.value);
  };

  const styles = {
    loginWithEmailSeparator: {
      border: '1px solid gray.200',
      width: '30%',
    },
  };

  return (
    <LoginLayout isPageLoading={isPageLoading}>
      <Flex direction='column' px={144} maxW={400} pt={176}>
        <Flex alignItems='flex-start'>
          <Button
            data-testid='back-btn'
            size='small'
            variant='ghost'
            colorScheme='primary'
            leftIcon={<Icon as={RiArrowLeftLine} />}
            onClick={() =>
              history.push({
                pathname: '/login',
                search: location.search,
                state: {loginFlow: true},
              })
            }
          >
            Back
          </Button>
        </Flex>
        <Heading as='h3' fontWeight='semibold' fontSize='20px' textAlign='center' mt={20}>
          Login to Spekit
        </Heading>

        <Flex direction='column' mt='14px' w='400px'>
          <Button
            data-testid='sso-btn'
            mt='10px'
            size='extralarge'
            variant='outlined'
            colorScheme='primary'
            borderColor='neutral.200'
            leftIcon={<Image src={ssoImage} />}
            onClick={() =>
              history.push({pathname: '/sso-login', search: location.search})
            }
          >
            Login with Single Sign-On (SSO)
          </Button>
          <Button
            data-testid='salesforce-btn'
            mt='10px'
            size='extralarge'
            variant='outlined'
            colorScheme='primary'
            borderColor='neutral.200'
            leftIcon={<Image src={salesforceImage} />}
            onClick={loginWithSalesforce}
          >
            Login with Salesforce
          </Button>
          {!shareParams ? (
            <Button
              data-testid='salesforce-sandbox-btn'
              mt='10px'
              size='extralarge'
              variant='outlined'
              colorScheme='primary'
              borderColor='neutral.200'
              leftIcon={<Image src={salesforceImage} />}
              onClick={loginWithSalesforceSandbox}
            >
              Login with Salesforce Sandbox
            </Button>
          ) : null}
        </Flex>

        <Flex mt='20px' mb='15px'>
          <hr style={styles.loginWithEmailSeparator} />
          <Text fontWeight='400' fontSize='12px' lineHeight='16.7px' color='gray.400'>
            or use your username
          </Text>
          <hr style={styles.loginWithEmailSeparator} />
        </Flex>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(loginHandler)}>
            <Flex gap={20} direction='column'>
              <UsernameInput
                autoFocus
                tabIndex={1}
                label='Username'
                placeholder='Enter username'
                colorScheme={
                  methods.formState.errors.username?.type === 'multiple-accounts'
                    ? 'warning'
                    : ''
                }
                onKeyDown={handleKeyDown}
                onChange={handleUsernameChange}
              />
              {methods.getValues('username') && (
                <React.Fragment>
                  <Fade in={true}>
                    <PasswordInput
                      autoFocus={isUsernamePresent}
                      tabIndex={2}
                      onKeyDown={handleKeyDown}
                      onChange={handlePasswordInputChange}
                    />
                  </Fade>

                  {isLoading ? (
                    <Flex justifyContent='center' alignItems='center' py={20}>
                      <Loader size={8} />
                    </Flex>
                  ) : (
                    <Fade in={true}>
                      <Button
                        data-testid='login-btn'
                        size='large'
                        variant='contained'
                        colorScheme='primary'
                        type='submit'
                        minW={400}
                      >
                        Login
                      </Button>
                    </Fade>
                  )}
                </React.Fragment>
              )}
            </Flex>
          </form>
        </FormProvider>
      </Flex>
    </LoginLayout>
  );
};

export default LoginAllOptions;
