import {getAllowedMimeTypes} from 'spekit-datalayer';
import {TAssetExtension} from 'spekit-types';

export const hasSupportedFileTypes = (fileType: string) => {
  const assetMimeTypes = getAllowedMimeTypes(true);
  return Object.values(assetMimeTypes).includes(fileType);
};

export const getFileExtension = (fileName: string) =>
  (fileName.split('.').pop()?.toLowerCase() as TAssetExtension) || '';

export const getFileName = (fileName: string, originalFileName: string) => {
  const fileExtension = getFileExtension(originalFileName);

  const newFileName = fileName.endsWith('.' + fileExtension)
    ? fileName
    : `${fileName}.${fileExtension}`;
  return newFileName;
};

export const extractFileName = (fileName: string) => {
  const ext = fileName.split('.').pop();
  return fileName.replace(`.${ext}`, '');
};
