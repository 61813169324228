/**
 * OTPPage
 *
 * Handles the OTP Verification page
 *
 * This page is rendered when the user successfully login with email from the 'view all login options' landing page
 */

import {useState, useEffect} from 'react';
import {auth, utils, verifyOTP} from 'spekit-datalayer';
import {IAuthLoginResponse} from 'spekit-types';
import {Link, useHistory, useLocation} from 'react-router-dom';
import LoginLayout from '../../components/LoginLayout';
import {
  Heading,
  Text,
  Flex,
  Input,
  FormControl,
  FormLabel,
  DSButton as Button,
  InputGroup,
  FormErrorMessage,
  useToast,
  Loader,
  Box,
  Link as DSLink,
} from 'spekit-ui';
import getFingerprint from '../../../../utils/browserFingerprint';

const OTPPage = () => {
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');
  const [regenerationError, setRegenerationError] = useState('');
  const [sessionExpired, setSessionExpired] = useState(false);
  const [resendLimit, setResendLimit] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');

  const toast = useToast();
  const history = useHistory();
  const location = useLocation<{email: string; loginFlow: boolean}>();

  useEffect(() => {
    async function checkSessionOnOTP() {
      if (!location.state) {
        setIsPageLoading(true);
        try {
          const fingerprint: string = await getFingerprint();
          const result: IAuthLoginResponse = await auth.login('', '', fingerprint);
          setIsPageLoading(false);
          if (result.logged_in) {
            const {redir} = utils.parseQs(location.search);
            history.push(redir ? decodeURIComponent(redir as string) : '/app/generate');
          }
        } catch (err) {
          history.push({
            pathname: '/login',
            search: location.search,
            state: {loginFlow: true},
          });
        }
      }
    }
    checkSessionOnOTP();
    let params = utils.parseQs(location.search);
    // only checking if params has any error attribute
    setLoggedIn(Boolean(params.error));
    const {email} = (location.state as {email: string}) || {email: ''};
    setEmailAddress(email);
  }, [history, location.search, location.state]);

  const regenPinHandler = async () => {
    setIsLoading(true);
    try {
      const resendLimitMsg = `You're trying too soon, please try again in a minute.`;
      setError('');
      setResendLimit(false);
      let result = await verifyOTP.regeneratePin();

      if (result.success) {
        toast({
          variant: 'success',
          description: 'An email containing your verification code was sent.',
          position: 'top-right',
        });
      } else {
        if (result.logged_in) {
          let {redir} = utils.parseQs(location.search);
          history.push(redir ? decodeURIComponent(redir as string) : '/app/generate');
        }
        // when session expired
        setSessionExpired(result.session_expired || false);
        // when resend pin limit reached
        if (result.message.toString() === resendLimitMsg) {
          setResendLimit(true);
        } else {
          setResendLimit(false);
          setRegenerationError(result.message);
        }
      }
    } catch (err) {
      setRegenerationError('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const pinHandler = async () => {
    if (pin) {
      setRegenerationError('');
      setSessionExpired(false);
      setResendLimit(false);
      setIsLoading(true);
      try {
        let result = await verifyOTP.verifyPin(pin.trim().toString());
        let {redir} = utils.parseQs(location.search);
        if (result.success) {
          history.push(
            redir && redir.includes('/app/generate')
              ? decodeURIComponent(redir as string)
              : {pathname: '/app/generate', search: location.search}
          );
        } else {
          if (result.logged_in) {
            history.push(redir ? decodeURIComponent(redir as string) : '/app/generate');
          }
          setError(result.message);
        }
      } catch (err) {
        setError('Something went wrong. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    } else {
      setError('A verification code is required to login.');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      pinHandler();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    setRegenerationError('');
    setResendLimit(false);
    setLoggedIn(false);
    setPin(event.target.value);
  };

  return (
    <LoginLayout isPageLoading={isPageLoading}>
      <Flex direction='column' px={144} w={384} pt={176}>
        <Heading as='h3' fontWeight='semibold' fontSize='20px' textAlign='center' mt={44}>
          Check your email
        </Heading>
        <Text variant='body2' fontWeight='normal' textAlign='center' mt={38} mb={44}>
          We’ve sent a 6-digit verification code to {emailAddress}. Didn’t get a code?{' '}
          <Box
            data-testid='resend-btn'
            onClick={regenPinHandler}
            cursor='pointer'
            color='primary.500'
            as='span'
            mr={4}
          >
            Click to resend.
          </Box>
        </Text>
        <FormControl
          mb={20}
          isInvalid={!!error || !!regenerationError || resendLimit || sessionExpired}
        >
          <FormLabel>Verification code</FormLabel>

          <InputGroup size='md'>
            <Input
              data-testid='verification-code'
              autoFocus
              placeholder='Enter verification code'
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              isInvalid={!!error || !!regenerationError || resendLimit || sessionExpired}
            />
          </InputGroup>
          {!pin && (
            <FormErrorMessage data-testid='required-error'>{error}</FormErrorMessage>
          )}
          {error && pin && (
            <>
              <FormErrorMessage data-testid='pin-error'>{error}</FormErrorMessage>
              <Text color='error.600' fontSize='small' fontWeight='normal'>
                <Box
                  data-testid='resend-verification-btn'
                  onClick={regenPinHandler}
                  cursor='pointer'
                  color='primary.500'
                  as='span'
                  mr={2}
                  ml={2}
                >
                  Click here{' '}
                </Box>
                to resend the verification code.
              </Text>
            </>
          )}
          {regenerationError && (
            <>
              <FormErrorMessage data-testid='regeneration-error'>
                {regenerationError}
              </FormErrorMessage>
              <Text color='error.600' fontSize='small' fontWeight='normal'>
                <DSLink
                  as={Link}
                  mr={2}
                  ml={2}
                  data-testid='login-again-btn'
                  to={{
                    pathname: '/login',
                    search: location.search,
                    state: {loginFlow: true},
                  }}
                >
                  Click here
                </DSLink>
                to login again
              </Text>
            </>
          )}

          {resendLimit && (
            <FormErrorMessage data-testid='too-many-requests'>
              Too many requests. Wait one minute and try again.
            </FormErrorMessage>
          )}
          {loggedIn && (
            <Flex direction='column' pt={12}>
              <Text color='error.600' fontSize='small' fontWeight='normal'>
                A login session is already in progress. Please provide the verification
                code you received in your email.
              </Text>
              <Text color='error.600' fontSize='small' fontWeight='normal'>
                <Box
                  data-testid='send-new-verification-btn'
                  onClick={regenPinHandler}
                  cursor='pointer'
                  color='primary.500'
                  as='span'
                  mr={4}
                >
                  Click here{' '}
                </Box>
                to send new verification code
              </Text>
              <Text color='error.600' fontSize='small' fontWeight='normal'>
                If you do not receive the verification code in your email, try logging in
                after 5 minutes.
              </Text>
            </Flex>
          )}
        </FormControl>
        {isLoading ? (
          <Flex justifyContent='center' alignItems='center' py={20}>
            <Loader size={8} />
          </Flex>
        ) : (
          <Button
            size='large'
            variant='contained'
            colorScheme='primary'
            data-testid='otp-login-btn'
            onClick={pinHandler}
          >
            Confirm and login
          </Button>
        )}
      </Flex>
    </LoginLayout>
  );
};

export default OTPPage;
