import {useSelector, useDispatch} from 'react-redux';
import {
  DSButton as Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  Avatar,
  Text,
  Box,
  Icon,
  useToast,
} from 'spekit-ui';
import {logging, profile} from 'spekit-datalayer';
import Support from '../../support';
import {RiDeleteBin6Line} from 'react-icons/ri';
import {RootState} from '../../reduxStore';
import {ChangeEvent, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {updateUserProfile} from '../../redux/actions';
import {IProfilePictureFormData, IProfilePictureResponse} from 'spekit-types';

const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1 MB
const VALID_FILE_TYPES = new Set(['image/jpeg', 'image/png', 'image/jpg']);
interface IUserProfile {
  first_name: string;
  last_name: string;
  profile_image: string;
  title: string;
  department: string;
}

const ProfileSettingsEdit = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const me = useSelector((state: RootState) => state.layout.me);
  const name = `${me.first_name} ${me.last_name}`;
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState('');
  const [userProfile, setUserProfile] = useState<IUserProfile>({
    first_name: '',
    last_name: '',
    profile_image: '',
    title: '',
    department: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isRemovingImage, setIsRemovingImage] = useState(false);

  useEffect(() => {
    if (me) {
      setUserProfile({
        first_name: me.first_name ?? '',
        last_name: me.last_name ?? '',
        profile_image: me.user_profile?.profile_image ?? '',
        title: me.user_profile?.title ?? '',
        department: me.user_profile?.department || '',
      });
      setImagePreview(me.user_profile?.profile_image ?? '');
    }
  }, [me]);

  const handleChange = (
    name: keyof IUserProfile,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const {value} = event.target;
    setUserProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && VALID_FILE_TYPES.has(file.type) && file.size <= MAX_FILE_SIZE) {
      setProfilePicture(file);
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
      setIsRemovingImage(false);
      toast({variant: 'success', description: 'Profile photo updated!'});
    } else {
      const errorMessage =
        !file || !VALID_FILE_TYPES.has(file.type)
          ? 'Invalid file format. Only JPG, JPEG, and PNG are allowed.'
          : 'Photo exceeds maximum file size of 1MB.';
      toast({variant: 'error', description: errorMessage});
    }
  };
  const removeFileHandler = () => {
    setProfilePicture(null);
    setImagePreview('');
    setIsRemovingImage(true);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setUserProfile((prevProfile) => ({
      ...prevProfile,
      profile_image: '',
    }));
    toast({
      variant: 'success',
      description: 'Profile picture removed and reverted to default selection',
    });
  };

  const isValid = () => {
    return userProfile.first_name && userProfile.first_name.trim().length > 0;
  };
  const save = async () => {
    try {
      if (!isValid()) {
        return;
      }
      setIsLoading(true);
      const data: IProfilePictureFormData = {
        title: userProfile.title,
        department: userProfile.department,
        user: {
          first_name: userProfile.first_name,
          last_name: userProfile.last_name,
        },
      };
      if (isRemovingImage) {
        data.profile_image = null;
      } else if (profilePicture) {
        data.profile_image = profilePicture;
      }
      const response: IProfilePictureResponse = await profile.updateProfile(me.id, data);
      const {user} = response;
      const userData = {
        title: response.title,
        department: response.department,
        profile_image: response.profile_image,
      };
      dispatch(
        updateUserProfile({
          first_name: user.first_name,
          last_name: user.last_name,
          user_profile: userData,
        })
      );
      history.push('/app/settings/profile');
    } catch (e) {
      logging.capture(e);
      toast({
        variant: 'error',
        description: 'Could not save changes to profile.',
      });
    } finally {
      setIsLoading(false);
      setIsRemovingImage(false);
    }
  };
  return (
    <Flex pt={52} direction='column'>
      <Box
        margin='auto'
        width='600px'
        border={1}
        borderRadius={4}
        borderColor='neutral.200'
        borderStyle='solid'
        bg='neutral.0'
        px={96}
        py='72px'
        textAlign='center'
        position='relative'
        boxSizing='border-box'
      >
        <Flex gap={24}>
          <Avatar
            name={name}
            sx={{
              img: {
                objectFit: 'fill',
              },
            }}
            src={imagePreview || undefined}
            size='2xl'
            bg={imagePreview ? 'transparent' : undefined}
            data-testid='profile-avatar'
            objectFit='fill'
          />
          <Flex direction='column' gap={6} justifyContent='flex-end'>
            <Flex gap={4}>
              <Button
                size='medium'
                variant='outlined'
                onClick={() => fileInputRef.current?.click()}
              >
                Upload photo
              </Button>
              <input
                ref={fileInputRef}
                style={{visibility: 'hidden', height: 0, width: 0}}
                type='file'
                accept='.png, .jpg, .jpeg'
                onChange={handleImageChange}
              />
              {(profilePicture || imagePreview) && (
                <Button
                  data-testid='remove-profile-image-icon'
                  leftIcon={<Icon as={RiDeleteBin6Line} />}
                  size='medium'
                  variant='danger'
                  w={6}
                  onClick={removeFileHandler}
                  tooltipLabel='Delete and reset to default profile picture'
                ></Button>
              )}
            </Flex>

            <Text
              fontWeight='normal'
              variant='caption1'
              color='neutral.600'
              textAlign='left'
            >
              .jpg, or .png, max 1MB
              <br />
              1:1 aspect ratio
            </Text>
          </Flex>
        </Flex>

        <Flex mt={32} direction='column' gap={24}>
          <Flex gap={12}>
            <FormControl isInvalid={!userProfile.first_name} isRequired>
              <FormLabel>First name</FormLabel>
              <Input
                placeholder='First name'
                value={userProfile.first_name}
                onChange={(event) => handleChange('first_name', event)}
                isInvalid={!userProfile.first_name}
              />
              {!userProfile.first_name && (
                <FormErrorMessage>Enter first name</FormErrorMessage>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>Last name</FormLabel>
              <Input
                placeholder='Last name'
                value={userProfile.last_name}
                onChange={(event) => handleChange('last_name', event)}
              />
            </FormControl>
          </Flex>

          <FormControl>
            <FormLabel>Job title</FormLabel>
            <Input
              placeholder='Job title'
              value={userProfile.title}
              onChange={(event) => handleChange('title', event)}
            />
          </FormControl>

          <FormControl mb={32}>
            <FormLabel>Department</FormLabel>
            <Input
              placeholder='Department'
              value={userProfile.department}
              onChange={(event) => handleChange('department', event)}
            />
          </FormControl>
        </Flex>
        <Text fontSize='small' color='neutral.800' data-testid='profile-edit-email'>
          Email: {me.email}
        </Text>
        <Text
          fontSize='small'
          color='neutral.800'
          data-testid='profile-edit-username'
          pb={30}
        >
          Username: {me.username}
        </Text>

        <Box>
          <Button
            size='medium'
            variant='contained'
            colorScheme='primary'
            data-testid='save-profile-changes'
            width='100%'
            onClick={save}
            isLoading={isLoading}
          >
            Save changes
          </Button>
        </Box>
      </Box>

      <Box
        mt={20}
        textAlign='center'
        fontSize={16}
        fontWeight='normal'
        color='neutral.800'
        width='645px'
        ml='auto'
        mr='auto'
      >
        <Support me={me} />
      </Box>
    </Flex>
  );
};
export default ProfileSettingsEdit;
