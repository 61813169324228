import {create, retrieve} from '../utils/APIHelpers';

export interface ILogoutResponse {
  success: boolean;
}

export interface ILogUninstallResponse {
  success: boolean;
  event_id: string;
}

export const logout = async (): Promise<ILogoutResponse> => {
  return await retrieve('/api/auth/logout');
};

export const logUninstall = async (
  application: 'chrome' | 'outlook' | 'slack'
): Promise<ILogUninstallResponse> => {
  const body = {
    type: 'user_progress',
    data: {
      [`${application}_installed`]: false,
    },
  };

  return await create(`/api/v1/events/`, body);
};
