import {getEnvironment, assertExhaustive} from '../utils/commonUtils';
import {getCurrentTab} from './helpers';
import {THandleCopy} from 'spekit-types';

export const copyToClipboard = async (data: THandleCopy): Promise<void> => {
  const environment = getEnvironment();
  switch (environment) {
    case 'webapp':
    case 'sidebar':
      // directly execute in these places we have permissions to copy.
      return navigator.clipboard.write([
        new ClipboardItem({
          'text/plain': new Blob([data.textPlain], {type: 'text/plain'}),
          'text/html': new Blob([data.textHtml || ''], {type: 'text/html'}),
        }),
      ]);
    case 'dom':
      // send to background which sends data to content script and we execute copying there.
      return chrome.runtime.sendMessage({
        msg: 'sendToContentScript',
        handler: 'handleCopy',
        data,
      });
    case 'background':
      // sends to active tab of content script and copies data there.
      const currentTab = await getCurrentTab();
      return chrome.tabs.sendMessage(currentTab.id!, {
        msg: 'handleCopy',
        data,
      });
    default:
      return assertExhaustive(environment);
  }
};
