import {lazy, Suspense} from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';
import {IFlagState, ISession} from 'spekit-types';

interface IState {
  flag: IFlagState;
  layout: {
    me: ISession;
  };
}

const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const ReportBuilder = lazy(() => import('./pages/ReportBuilder/ReportBuilder'));
const ShareLinks = lazy(() => import('./pages/ShareLinks/ShareLinks'));
const ShareLinksByCompany = lazy(() => import('./pages/ShareLinks/ShareLinksByCompany'));
const CompanyDetails = lazy(() => import('./pages/ShareLinks/CompanyDetails'));

const ShareLinkPaidAnalytics = lazy(
  () => import('./pages/ShareLinks/ShareLinkAnalytics')
);
const ShareLinkPaidAnalyticsExplo = lazy(
  () => import('./pages/ShareLinks/ShareLinkAnalyticsExplo')
);

const AnalyticsRoutes = () => {
  const flags = useSelector((state: IState) => state.flag);

  const getShareLinkAnalyticRoutes = () => {
    if (!flags.hasSpekLinkGenerationFlag) {
      return <Redirect to='/app/wiki/topics' />;
    }
    if (flags.hasCompanyLinksFlag) {
      return <ShareLinksByCompany />;
    }
    return <ShareLinks />;
  };

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route path='/app/analytics/activation-dashboard'>
          <Dashboard title='User Activation Dashboard' dashboardSerial='GnAkEgax3l' />
        </Route>
        <Route path='/app/analytics/adoption-dashboard'>
          <Dashboard title='Adoption Dashboard' dashboardSerial='ovARWDnYlV' />
        </Route>
        <Route path='/app/analytics/governance-dashboard'>
          <Dashboard title='Governance Dashboard' dashboardSerial='OVxbPEpADm' />
        </Route>
        <Route path='/app/analytics/content-creation-dashboard'>
          <Dashboard title='Content Creation Dashboard' dashboardSerial='V4ArawQ1mD' />
        </Route>
        <Route path='/app/analytics/topics-dashboard'>
          <Dashboard title='Topics Dashboard' dashboardSerial='L9YmadpYXl' />
        </Route>
        <Route path='/app/analytics/flows-dashboard'>
          {flags.hasFlowFlag ? (
            <Dashboard title='Flows Dashboard' dashboardSerial='QRA3vdK1Ed' />
          ) : (
            <Redirect to='/app/wiki/topics' />
          )}
        </Route>
        <Route path='/app/analytics/knowledge-checks-dashboard'>
          {flags.hasQuizFlag ? (
            <Dashboard title='Knowledge Checks Dashboard' dashboardSerial='PD1jaElxvm' />
          ) : (
            <Redirect to='/app/wiki/topics' />
          )}
        </Route>
        <Route path='/app/analytics/share-link-activity'>
          {getShareLinkAnalyticRoutes()}
        </Route>
        <Route path='/app/analytics/company-detail'>
          {flags.hasSpekLinkGenerationFlag && flags.hasCompanyLinksFlag ? (
            <CompanyDetails />
          ) : flags.hasSpekLinkGenerationFlag ? (
            <ShareLinks />
          ) : (
            <Redirect to='/app/wiki/topics' />
          )}
        </Route>
        <Route path='/app/analytics/share-link-analytics'>
          {flags.hasSpekLinkGenerationFlag ? (
            flags.hasSmartSendInternalAnalyticsFlag ? (
              <ShareLinkPaidAnalytics />
            ) : (
              <ShareLinkPaidAnalyticsExplo />
            )
          ) : (
            <Redirect to='/app/wiki/topics' />
          )}
        </Route>

        <Route path='/app/analytics/spotlights-dashboard'>
          {flags.hasSpotlightsDashboardFlag ? (
            <Dashboard title='Spotlights Dashboard' dashboardSerial='7Jx6BLnxwe' />
          ) : (
            <Redirect to='/app/wiki/topics' />
          )}
        </Route>
        <Route path='/app/analytics/smartsend-dashboard'>
          {flags.hasSmartsendDashboardFlag ? (
            <Dashboard title='Smartsend Dashboard' dashboardSerial='p9170DbAa3' />
          ) : (
            <Redirect to='/app/wiki/topics' />
          )}
        </Route>
        <Route path='/app/analytics/custom-reports'>
          {flags.hasCustomReportsDashboardFlag ? (
            <ReportBuilder title='Custom Reports' reportSerial='zOVxbyYDmk' />
          ) : (
            <Redirect to='/app/wiki/topics' />
          )}
        </Route>
      </Switch>
    </Suspense>
  );
};

export default AnalyticsRoutes;
