import {
  ICreateSpekResponse,
  IDeleteSpekPayload,
  IEditSpekPayload,
  IRTEFileUploadResponse,
  ISpekNotificationResponse,
  ISpekPermissionResponse,
  ITask,
  IWikiResponse,
  PluralTermType,
  TCreateSpekPayload,
  TEditSpekResponse,
  TGetSpekPayload,
  TTermType,
} from 'spekit-types';
import {
  BASE_WIKI,
  SPEK_API,
  SPEK_HIDE_API,
  SPEK_NOTIFICATIONS,
  SPEK_UNHIDE_API,
  WIKI_API,
} from '../api';
import {spekPermissions} from '../constants';
import {create, createForm, destroy, modify, retrieve, update} from '../utils/APIHelpers';
import {stringifyQs} from '../utils/commonUtils';
import {editAPIToState, getStateToAPI, stateToAPI} from './transformers';

export const getSpek = async (payload: TGetSpekPayload) => {
  const params = stringifyQs(getStateToAPI(payload), {
    addQueryPrefix: true,
    skipNulls: true,
  });
  const response = await retrieve<IWikiResponse>(`${WIKI_API}${params}`);
  return response;
};

export const createSpek = async (state: TCreateSpekPayload) => {
  const payload = stateToAPI(state);
  const response: ICreateSpekResponse = await create(SPEK_API, payload);
  return response;
};

export const editSpek = async ({state, id, type}: IEditSpekPayload) => {
  const payload = stateToAPI(state);
  const response = await modify<unknown, TEditSpekResponse>(
    `${BASE_WIKI}/${type}s/${id}/`,
    payload
  );
  const transformedData = editAPIToState(response, state);
  return {response: transformedData, payload: state};
};

export const deleteSpek = ({id, type}: IDeleteSpekPayload) => {
  const makeEndPoint = () => {
    const base = '/api/';
    if (type === 'asset') return `${base}topic_assets/${id}`;
    return `${base}wiki/${type}s/${id}`;
  };
  const deleteEndpoint = makeEndPoint();
  return destroy<ITask>(deleteEndpoint, false);
};

export const getNotifications = async (id: string, type: string) => {
  const response = await retrieve<ISpekNotificationResponse>(
    `${SPEK_NOTIFICATIONS}${type}s/${id}`
  );
  return response;
};

export const hideUnhideSpek = async (
  id: string,
  type: string,
  hide = true
): Promise<ITask> => {
  const payload = {
    [`${type}s`]: [id],
  };
  const response = await create<{[x: string]: string[]}, ITask>(
    hide ? SPEK_HIDE_API : SPEK_UNHIDE_API,
    payload
  );
  return response;
};

export const markSpekShareable = async (id: string, type: string, shareable = true) => {
  const response = await modify(`${BASE_WIKI}/${type}s/${id}/`, {shareable});
  return response;
};

export const hasPermission = async (
  id: string,
  type: TTermType,
  permission: 'shareable' | 'hideable'
) => {
  const pluralType = (type + 's') as PluralTermType;
  const payload = {
    [pluralType]: [id],
    permissions: spekPermissions[permission],
  };
  const {data} = await update<ISpekPermissionResponse>(
    `${BASE_WIKI}/has_perms_any`,
    payload
  );
  return data[pluralType].length > 0;
};

export const getMentions = async (query: string) => {
  const params = {
    q: query,
    type: 'all',
    source: 'rte-mention-search',
    limit: 6,
    offset: 0,
    exclude_undefined: true,
    include_read_speks_flag: true,
  };
  const {results} = await retrieve<IWikiResponse>(
    `${WIKI_API}${stringifyQs(params, {addQueryPrefix: true})}`
  );
  return results.map((spek) => ({
    id: `#${spek.label}`,
    label: spek.label,
    link: spek.link,
    query,
    type: spek.type,
    contentType: spek.content_type || '',
  }));
};

export const uploadFileRTE = async (payload: FormData) =>
  createForm<IRTEFileUploadResponse>('/api/media/handle_upload/', payload);
