import React from 'react';
import {RiCheckLine, RiSubtractLine} from 'react-icons/ri';
import {Icon} from 'spekit-ui';

export const CheckBoxIcon = ({variant, ...rest}: any) => {
  const baseStyle = {
    borderRadius: '4px',
    width: '14px',
    height: '14px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'var(--spekit-colors-primary-500)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5px 0',
    boxSizing: 'border-box',
    flexShrink: 0,
  };

  switch (variant) {
    case 'all':
      return (
        <span
          style={{
            ...baseStyle,
            backgroundColor: 'var(--spekit-colors-primary-500)',
            color: 'var(--spekit-colors-neutral-0)',
          }}
          {...rest}
        >
          <Icon as={RiCheckLine} />
        </span>
      );
    case 'none':
      return <span style={baseStyle} {...rest} />;
    case 'some':
      return (
        <span
          style={{
            ...baseStyle,
            backgroundColor: 'var(--spekit-colors-primary-500)',
            color: 'var(--spekit-colors-neutral-0)',
          }}
          {...rest}
        >
          <Icon as={RiSubtractLine} {...rest} />
        </span>
      );
    default:
      return null;
  }
};
