import React, {RefObject, useEffect, useState} from 'react';
import {RiCloseLine, RiErrorWarningLine, RiAddCircleLine} from 'react-icons/ri';
import {useDropzone, FileRejection} from 'react-dropzone';
import {
  ActionWithConfirmation,
  DSModal as Modal,
  Heading,
  HStack,
  ModalBody,
  Text,
  Box,
  Flex,
  DSButton as Button,
  Divider,
  PrivacySettings,
  Alert,
  AlertDescription,
  Icon,
  notifications,
  TAlertVariant,
  Link,
} from 'spekit-ui';
import {FilePreview} from './FilePreview';
import {
  topics as TopicsAPI,
  utils,
  getDropzoneAcceptedFiles,
  CONTENT_SHARING,
  notification,
} from 'spekit-datalayer';
import {
  IExtendedNotificationState,
  IOptionType,
  TCustomFieldValues,
  IFinalizedAPIPayload,
  TAssetMimeType,
} from 'spekit-types';
import type {ICloseProps} from '../AssetModal';
import {TopicSelector} from '../../TopicSelector';
import {CustomFieldsEditor} from '../../Content';
import {hasSupportedFileTypes, getFileExtension, extractFileName} from '../helpers';
import {
  INVALID_FILE_SIZE_MESSAGE,
  INVALID_LARGE_FILE_SIZE_MESSAGE,
  FILE_SIZE_LIMIT,
  LARGE_FILE_SIZE_LIMIT,
  INVALID_FILE_MESSAGE,
  SUPPORTED_FORMAT_TEXT,
  MAX_FILE_LABEL_CHARACTERS,
  SUPPORTED_FORMAT_TEXT_EXTENDED,
} from '../constants';
import {NotificationButton} from '../../Notifications/NotificationButton';

const {prepareNotificationPayload} = notification;

interface Props {
  isOpen: boolean;
  onClose: (closeArgs?: ICloseProps) => void;
  containerRef?: RefObject<HTMLElement>;
  track?: any;
  selectedFiles?: File[] | null;
  selectedTopic?: IOptionType | null;
  hasLargeFileUploadFlag?: boolean;
  hasExtendedFileTypesFlag?: boolean;
  host?: string | null;
}

interface UploadedFile {
  isUploading: boolean;
  fileName: string;
  originalFile: File;
  hasUploaded: boolean;
  errorMessage?: string;
  warnMessage?: string;
}

const filesNormalizer = (acceptedFiles: File[]) => {
  return acceptedFiles.map((file) => ({
    originalFile: file,
    fileName: extractFileName(file.name),
    isUploading: false,
    hasUploaded: false,
  }));
};

export const CreateAsset = (props: Props) => {
  const {
    isOpen,
    onClose,
    containerRef,
    track,
    selectedTopic,
    hasLargeFileUploadFlag,
    hasExtendedFileTypesFlag,
    host,
  } = props;
  const fileSizeLimit = hasLargeFileUploadFlag ? LARGE_FILE_SIZE_LIMIT : FILE_SIZE_LIMIT;
  const fileSizeOverLimitMsg = hasLargeFileUploadFlag
    ? INVALID_LARGE_FILE_SIZE_MESSAGE
    : INVALID_FILE_SIZE_MESSAGE;
  const {notify} = notifications;
  const currentURL = window.location.href;

  const [selectedTopics, setSelectedTopics] = useState<IOptionType[]>([]);

  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<{
    type: TAlertVariant;
    message: string;
  }>({type: 'success', message: ''});
  const [isTopicError, setIsTopicError] = useState(false);
  const [privacyIsChecked, setPrivacyIsChecked] = useState(false);
  const [customFields, setCustomFields] = useState<TCustomFieldValues>({});
  const [skipCloseConfirmation, setSkipCloseConfirmation] = useState(false);
  const [isFileUnsupported, setIsFileUnsupported] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [showLoaderOnly, setShowLoaderOnly] = useState(false);

  const handleClose = (
    isSuccessful?: boolean,
    notification?: IExtendedNotificationState,
    fileDetails?: Partial<IOptionType>,
    isLF?: boolean
  ) => {
    track('Asset upload modal', {message: 'modal_closed'});
    // double track calls till we transition from old event keys to new ones
    track('Modal closed', 'Asset Upload modal');

    setFiles([]);
    setSelectedTopics([]);
    setIsTopicError(false);
    setIsAlertOpen(false);
    setSkipCloseConfirmation(false);
    setPrivacyIsChecked(false);
    setIsFileUnsupported(false);
    setSizeError(false);
    onClose({
      isSuccessful,
      selectedTopics,
      files: files.length,
      fromEdit: false,
      notification,
      fileDetails,
      isLF,
    });
  };

  const duplicateFileCheck = async (uploadedFile: UploadedFile) => {
    const formData = new FormData();
    formData.append('file_name', uploadedFile.fileName);
    const hashMD5 = await utils.getFileMD5(uploadedFile.originalFile);
    formData.append('hash_md5', hashMD5);

    try {
      await TopicsAPI.duplicateFile(formData);
      uploadedFile.warnMessage = '';
    } catch (err) {
      uploadedFile.warnMessage = err.message;
    }
    return uploadedFile;
  };

  const handleBlurFileState = async (fileIndex: number, key: string, value: any) => {
    const updatedFiles = [...files];
    if (key === 'fileName' && value.length > 0) {
      updatedFiles[fileIndex] = await duplicateFileCheck(updatedFiles[fileIndex]);
    }
    setFiles(updatedFiles);
  };

  const handleChangeFileState = async (fileIndex: number, key: string, value: any) => {
    const updatedFiles = [...files];
    updatedFiles[fileIndex][key] = value;
    updatedFiles[fileIndex]['warnMessage'] = '';
    setFiles(updatedFiles);
  };

  const handleRemove = (fileIndex: number) => () => {
    const updatedFiles = [...files];
    updatedFiles.splice(fileIndex, 1);
    setFiles(updatedFiles);
  };

  const handleFileDrop = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[]
  ) => {
    setIsFileUnsupported(false);
    setSizeError(false);
    setIsAlertOpen(false);
    if (fileRejections.length > 0) {
      setIsAlertOpen(true);
      setSkipCloseConfirmation(true);
      let message = '';
      if (!hasSupportedFileTypes(fileRejections[0].file.type)) {
        message = INVALID_FILE_MESSAGE;
        track('Asset upload modal', {message: 'unsupported_file_type'});
        setIsFileUnsupported(true);
      } else if (fileRejections[0].file.size > fileSizeLimit) {
        message = fileSizeOverLimitMsg;
        track('Asset upload modal', {message: 'file_size_exceeded'});
        setSizeError(true);
      } else {
        message = 'Something went wrong. Please try again.';
      }
      setAlertMessage({
        type: 'error',
        message: message,
      });
    } else {
      const uploaded = filesNormalizer(acceptedFiles);
      setFiles([...files, ...uploaded]);
      duplicateCheck(uploaded);
      track('Asset upload modal', {message: 'assets_dropped_in_dropzone'});
      // double track calls till we transition from old event keys to new ones
      track('Dropped assets in the dropzone', 'Asset Upload Modal');
    }
  };

  const handleUploadMultiPart = async (notification: IExtendedNotificationState) => {
    const notificationToSend = prepareNotificationPayload(notification);

    if (selectedTopics.length === 0) {
      setIsTopicError(true);
      return;
    }
    const topicIds = selectedTopics.map((topic) => topic.value);

    let itemsToUpload = files.filter((file) => !file.hasUploaded);

    const goForUpload = itemsToUpload.every(
      (toUpload) =>
        toUpload.fileName.trim().length > 0 &&
        toUpload.fileName.trim().length <= MAX_FILE_LABEL_CHARACTERS
    );

    if (goForUpload !== true) {
      return;
    }

    const offset = files.length - itemsToUpload.length;

    const notificationTeams: any = [];
    notification.teams.forEach((team) => {
      notificationTeams.push(team.value);
    });

    const fileDetails: Partial<IOptionType> = {};

    const uploadPromises = itemsToUpload.map(async (toUpload, i) => {
      const index = offset + i;
      const payload: any = {
        file_size: toUpload.originalFile.size,
        content_type: toUpload.originalFile.type,
        file_name: toUpload.originalFile.name,
        tags_list: topicIds,
        label: toUpload.fileName,
        shareable: privacyIsChecked.toString(),
        custom_columns: customFields,
      };

      if (notificationTeams.length > 0) {
        payload.teams = notificationTeams;
      }
      if (notificationToSend) {
        payload.notification = notificationToSend;
      }

      handleChangeFileState(index, 'errorMessage', null);
      handleChangeFileState(index, 'isUploading', true);

      try {
        const {upload_id, object_key, urls, file_id} =
          await TopicsAPI.startMultiPartUpload(payload);

        const chunks = utils.createFileChunks(
          toUpload.originalFile,
          Math.ceil(toUpload.originalFile.size / urls.length)
        );

        let promises = [];
        for (let j = 0; j < chunks.length; j++) {
          promises.push(TopicsAPI.doMultiPartUpload(urls[j], chunks[j]));
        }
        const responses = await Promise.all(promises);

        let finalizedPayload: IFinalizedAPIPayload = {
          upload_id: upload_id,
          object_key: object_key,
          parts: [],
          file_id: file_id,
        };

        responses.forEach((res, _index) => {
          finalizedPayload.parts.push({
            part_number: _index + 1,
            //@ts-ignore
            etag: res.headers.get('ETag').replace(/['"]+/g, ''),
          });
        });

        await TopicsAPI.finalizedMultiPartUpload(finalizedPayload);

        handleChangeFileState(index, 'hasUploaded', true);
        handleChangeFileState(index, 'isUploading', false);

        track('uploaded asset successfully', 'Asset Upload Modal', {
          notification: notificationToSend ?? null,
        });
        // double track calls till we transition from old event keys to new ones
        track('Asset upload modal', {
          message: 'uploaded_asset_successfully',
          fileId: file_id,
          fileType: payload.content_type,
          notification: notificationToSend ?? null,
        });
        fileDetails.value = file_id;
        fileDetails.label = payload.file_name;
      } catch (e) {
        handleChangeFileState(
          index,
          'errorMessage',
          'An error occurred during upload. Please try again.'
        );
        handleChangeFileState(index, 'isUploading', false);
        throw e;
      }
    });

    const results = await Promise.allSettled(uploadPromises);

    const rejectedLength = results.filter((r) => r.status === 'rejected').length;

    // if all passed
    if (rejectedLength === 0) {
      setIsAlertOpen(true);
      setSkipCloseConfirmation(true);
      setAlertMessage({
        type: 'success',
        message: 'Success! Your files have been uploaded.',
      });
      notify({
        text: notificationToSend
          ? `File upload in progress. You will be notified when it's successful, and user will be notified!`
          : 'File upload in progress. You will not see the new file until the upload is successful.',
      });
      const isLF = true;
      handleClose(true, notification, fileDetails, isLF);
    }
  };

  const duplicateCheck = async (uploadedFiles: UploadedFile[]) => {
    const updatedFiles = [];
    setShowLoaderOnly(true);
    for (const file of uploadedFiles) {
      const updatedFile = await duplicateFileCheck(file);
      updatedFiles.push(updatedFile);
    }

    setFiles([...files, ...updatedFiles]);
    setShowLoaderOnly(false);
  };

  const handleUpload = async (notification: IExtendedNotificationState) => {
    /*
     * Uploads all the files in the files array at once
     */
    const notificationToSend = prepareNotificationPayload(notification);

    if (selectedTopics.length === 0) {
      setIsTopicError(true);
      return;
    }

    // filter already uploaded files
    let toUpload = files.filter((file) => !file.hasUploaded);

    // index difference we get when we filter out already uploaded files
    const offset = files.length - toUpload.length;

    let promises: Promise<void>[] = [];
    const fileDetails: Partial<IOptionType> = {};
    // handle each upload individually
    toUpload.forEach((file, i) => {
      // filename validation

      if (
        !(
          file.fileName.trim().length > 0 &&
          file.fileName.trim().length <= MAX_FILE_LABEL_CHARACTERS
        )
      ) {
        return;
      }

      const p = new Promise<void>(async (resolve, reject) => {
        const index = offset + i;
        const formData = new FormData();

        // add all topics to the form data
        selectedTopics.forEach((topic) => {
          formData.append('tags_list', topic.value);
        });

        notification.teams.forEach((team) => {
          formData.append('teams', team.value);
        });

        formData.append('file', file.originalFile);

        formData.append('file_name', file.fileName);

        formData.append('shareable', privacyIsChecked.toString());
        formData.append('custom_columns', JSON.stringify(customFields));

        if (notificationToSend) {
          formData.append('notification', JSON.stringify(notificationToSend));
        }

        handleChangeFileState(index, 'errorMessage', null);
        handleChangeFileState(index, 'isUploading', true);

        try {
          const response = await TopicsAPI.uploadAssets(formData);
          handleChangeFileState(index, 'hasUploaded', true);
          handleChangeFileState(index, 'isUploading', false);
          track('uploaded asset successfully', 'Asset Upload Modal', {
            notification: notificationToSend ?? null,
          });
          // double track calls till we transition from old event keys to new ones
          track('Asset upload modal', {
            message: 'uploaded_asset_successfully',
            fileId: response.file.id,
            fileType: response.file.content_type,
            notification: notificationToSend ?? null,
          });
          fileDetails.value = response.file.id;
          fileDetails.label = response.file.file_name;
          resolve();
        } catch (err) {
          handleChangeFileState(
            index,
            'errorMessage',
            'Upload failed. Try uploading your file again.'
          );
          handleChangeFileState(index, 'isUploading', false);
          track('error uploading asset', 'Asset Upload Modal');
          // double track calls till we transition from old event keys to new ones
          track('Asset upload modal', {
            message: 'error_uploading_asset',
            errorMessage: err.message,
          });
          reject();
        }
      });
      promises.push(p);
    });

    const results = await Promise.allSettled(promises);

    const rejectedLength = results.filter((r) => r.status === 'rejected').length;

    // if at least 1 passed
    if (
      (rejectedLength > 0 && rejectedLength < toUpload.length) ||
      toUpload.length !== promises.length
    ) {
      setIsAlertOpen(true);
      setSkipCloseConfirmation(true);
      setAlertMessage({type: 'warning', message: 'Uh oh! Some files failed to upload.'});
    }
    // if all passed
    else if (rejectedLength === 0) {
      setIsAlertOpen(true);
      setSkipCloseConfirmation(true);
      setAlertMessage({
        type: 'success',
        message: 'Success! Your files have been uploaded.',
      });

      notify({
        text: notificationToSend
          ? 'Files uploaded and users notified!'
          : 'Success! Your files have been uploaded.',
      });
      handleClose(true, notification, fileDetails);
    }
  };

  const handleAlertClose = () => {
    setIsAlertOpen(false);
    setIsFileUnsupported(false);
    setSizeError(false);
  };

  const validateTopic = async (topicName: string, topicId: string) => {
    if (!topicId || !topicName) return;
    const topics = await TopicsAPI.get(topicName, {allowedOnly: true});
    const topic = topics.find((topic) => topic.value === topicId);
    if (topic) setSelectedTopics([{label: topic.label, value: topic.value}]);
  };

  useEffect(() => {
    if (props.selectedFiles) {
      const uploaded = filesNormalizer(props.selectedFiles);
      setFiles(uploaded);
    }
  }, [props.selectedFiles]);

  useEffect(() => {
    const getValidTopic = async () => {
      if (selectedTopic) {
        validateTopic(selectedTopic.label, selectedTopic.value);
      } else if (currentURL) {
        const {topic, tag} = utils.parseQs(currentURL) as {topic: string; tag: string};
        validateTopic(tag, topic);
      }
    };

    getValidTopic();
  }, [currentURL, selectedTopic]);

  // cleanup: simplify this
  const isUploadButtonDisabled =
    files.length === 0 ||
    files.some((file) => file.isUploading) ||
    !files.some((f) => !f.hasUploaded);
  const acceptedFiles = getDropzoneAcceptedFiles(hasExtendedFileTypesFlag);

  const {open, getInputProps, getRootProps, isDragActive} = useDropzone({
    onDrop: handleFileDrop,
    noClick: true,
    noKeyboard: true,
    maxSize: fileSizeLimit,
    accept: acceptedFiles,
    disabled: files.some((f) => f.isUploading),
  });

  return (
    <Modal
      portalProps={{containerRef}}
      isOpen={isOpen}
      closeOnEsc={false}
      onClose={handleClose}
      size='md'
      trapFocus={false}
      blockScrollOnMount={false}
      scrollBehavior='inside'
      isCentered
      shouldWrapOverSidebar
      containerProps={{
        alignItems: 'baseline',
      }}
      variant='file'
      id='create-asset-modal'
    >
      <Flex alignItems='center' px={24} pt={24}>
        <Heading as='h4' fontWeight='semibold' data-testid='modal-heading'>
          Upload files into Spekit
        </Heading>
        <Flex alignItems='center' ml='auto'>
          {files.length > 0 && (
            <>
              <NotificationButton
                contentType='File'
                onSave={(notification) => {
                  hasLargeFileUploadFlag
                    ? handleUploadMultiPart(notification)
                    : handleUpload(notification);
                }}
                disabled={isUploadButtonDisabled}
                isSubmitting={files.some((file) => file.isUploading)}
                mode='upload'
                testId='btn-upload'
                topics={selectedTopics.map((topic) => topic.value) || []}
              />
              <Divider orientation='vertical' h='30px' ml={20} mr={10} />
            </>
          )}
          <ActionWithConfirmation
            icon={RiCloseLine}
            confirmationHeader='Are you sure you want to close?'
            confirmationMessage='Changes made will be lost once you close this window.'
            confirmActionText='Yes, close'
            confirmAction={handleClose}
            actionTooltip='Close'
            skipConfirmation={files.length === 0 || skipCloseConfirmation}
          />
        </Flex>
      </Flex>

      <ModalBody px={24} mt={16}>
        {isAlertOpen && (
          <Alert
            data-testid='alert-message'
            variant={alertMessage.type}
            onClose={handleAlertClose}
            mb={12}
          >
            <AlertDescription>{alertMessage.message}</AlertDescription>
          </Alert>
        )}

        <Box
          {...getRootProps({})}
          justifyContent='center'
          alignItems='center'
          borderRadius={12}
          cursor='pointer'
          flexDirection='column'
          height='280px'
          borderColor={isDragActive ? 'primary.500' : 'neutral.200'}
          borderWidth={2}
          borderStyle='dashed'
          display='flex'
          gap={10}
          data-testid='dropzone'
        >
          <input {...getInputProps()} data-testid='dropzone-input' />
          <Text variant='body2' fontWeight='semibold'>
            Drag and drop your files here
          </Text>
          <Text variant='body2' fontWeight='semibold'>
            or
          </Text>
          <Button
            variant='outlined'
            size='large'
            onClick={open}
            data-testid='dropzone-button'
            disabled={files.some((f) => f.isUploading)}
          >
            Browse
          </Button>
        </Box>

        {/* dropzone caption */}
        <Flex mt={12} justifyContent='space-between' data-testid='file-constraints'>
          <Text
            variant='body2'
            color={isFileUnsupported ? 'error.500' : 'neutral.600'}
            display='flex'
            alignItems='center'
            gap={4}
            data-testid='supported-format'
          >
            {isFileUnsupported && <Icon as={RiErrorWarningLine} h='18px' w='18px' />}{' '}
            {hasExtendedFileTypesFlag
              ? SUPPORTED_FORMAT_TEXT_EXTENDED
              : SUPPORTED_FORMAT_TEXT}
          </Text>
          <Text
            variant='body2'
            color={sizeError ? 'error.500' : 'neutral.600'}
            display='flex'
            alignItems='center'
            gap={4}
            data-testid='max-file-size'
          >
            {sizeError && <Icon as={RiErrorWarningLine} h='18px' w='18px' />} Max file
            size: {fileSizeLimit / 1024 ** 2} MB
          </Text>
        </Flex>

        {/* file list */}
        {files.length > 0 && (
          <>
            <Flex
              mt={24}
              gap={10}
              direction='column'
              data-testid='uploaded-files-list'
              maxH='340px'
              overflowY='auto'
            >
              {files.map((file, i) => (
                <FilePreview
                  key={i}
                  errorMessage={file.errorMessage}
                  warnMessage={file.warnMessage}
                  hasUploaded={file.hasUploaded}
                  isUploading={showLoaderOnly || file.isUploading}
                  fileName={file.fileName}
                  fileSize={file.originalFile.size}
                  fileExtension={getFileExtension(file.originalFile.name)}
                  contentType={file.originalFile.type as TAssetMimeType}
                  onRemove={handleRemove(i)}
                  onChange={(updatedFileName: string) =>
                    handleChangeFileState(i, 'fileName', updatedFileName)
                  }
                  onBlur={(updatedFileName: string) =>
                    handleBlurFileState(i, 'fileName', updatedFileName)
                  }
                  showLoaderOnly={showLoaderOnly}
                />
              ))}
            </Flex>

            <Divider mt={24} />

            {/* topic selector */}
            <HStack alignItems='end' width='100%'>
              <Box maxW='500px' width='100%' mt={24}>
                <TopicSelector
                  dataTestId='topic-label'
                  errorTestId='topic-error'
                  placeholder='Select Topics'
                  label='Add to Topics'
                  defaultValues={selectedTopics}
                  handleTopicsChange={(newValue) => {
                    // typecasting here because <Select /> generics are messed up
                    setSelectedTopics(newValue as IOptionType[]);
                    setIsTopicError(false);
                  }}
                  isInvalid={isTopicError}
                  allowedOnlyTopics
                  isDisabled={files.some((file) => file.isUploading)}
                  blurInputOnSelect
                />
              </Box>
              <Button
                dataTestId='create-topic-btn'
                href={`${host || ''}/app/wiki/topics/create`}
                as={Link}
                colorScheme='primary'
                leftIcon={<Icon h='18px' w='18px' as={RiAddCircleLine} />}
                size='large'
                variant='ghost'
                target='_blank'
              >
                Create Topic
              </Button>
            </HStack>

            <Box mt={24}>
              <CustomFieldsEditor
                mode={files.some((file) => file.isUploading) ? 'view' : 'create'}
                values={customFields}
                updateValues={(values) => setCustomFields(values)}
                type='file'
              />
            </Box>

            {/* privacy settings */}
            <Box mt={24} mb={64}>
              <PrivacySettings
                variant='asset'
                alertMessage='Existing external links for this content will no longer be accessible.'
                data-testid='asset-external-share-checkbox'
                isChecked={privacyIsChecked}
                onChange={() => {
                  setPrivacyIsChecked(!privacyIsChecked);
                }}
                isDisabled={files.some((file) => file.isUploading)}
              >
                {CONTENT_SHARING.ALL_USERS}
              </PrivacySettings>
            </Box>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default CreateAsset;
