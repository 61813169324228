/**
 * This is the default configuration for the editor.
 * This config defines the available plugins, options, and toolbar layout for the editor.
 * Additional configuration parameters can be passed to the editor react component.
 * Access configuration params in plugins via the editor.config.get method: editor.config.get('myCustomConfigKey')
 */

// out of box plugins
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import AutoImage from '@ckeditor/ckeditor5-image/src/autoimage';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import {BlockQuote} from '@ckeditor/ckeditor5-block-quote';
import Code from '@ckeditor/ckeditor5-basic-styles/src/code';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';
import DocumentList from '@ckeditor/ckeditor5-list/src/documentlist';
import DocumentListProperties from '@ckeditor/ckeditor5-list/src/documentlistproperties';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Link from '@ckeditor/ckeditor5-link/src/link';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import {Mention} from '@ckeditor/ckeditor5-mention';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Table from '@ckeditor/ckeditor5-table/src/table'; // WIP - fast follow
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'; // WIP - fast follow
import TableColumnResize from '@ckeditor/ckeditor5-table/src/tablecolumnresize'; // WIP - fast follow
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount';

// custom plugins
import DisallowNestingTables from '../plugins/disallowNestingTables';
import EmbedEditing from '../plugins/embed/embedEdit';
import InsertFile from '../plugins/insertFile';
import InsertIframe from '../plugins/insertIframe';
import IframeEditing from '../plugins/embed/insertIframeEdit';
import IframeToolbar from '../plugins/iframeToolbar';
import LimitIndent from '../plugins/limitIndent';
import {MentionCustomization} from '../plugins/mentions';
import SpecialCharactersEmoji from '../plugins/specialCharactersEmoji';
import SpotlightHeader from '../plugins/spotlightHeader';

// AI
import SpekitAI from '../plugins/spekitAI';
import sparklingIcon from '../icons/sparkling.svg';
// config variables
import {colors, editorHeadingOptions, editorToolbarOptions} from './options';

// default editor plugins
const editorPlugins = [
  // out of box plugins
  Autoformat,
  CodeBlock,
  Font,
  HorizontalLine,
  RemoveFormat,
  SpecialCharacters,
  Strikethrough,
  Table,
  TableColumnResize,
  TableToolbar,
  Alignment,
  AutoImage,
  AutoLink,
  BlockQuote,
  Bold,
  Code,
  DocumentList,
  DocumentListProperties,
  Essentials,
  Heading,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  Mention,
  MentionCustomization,
  Paragraph,
  PasteFromOffice,
  Underline,

  // custom plugins
  DisallowNestingTables,
  EmbedEditing,
  IframeEditing,
  IframeToolbar,
  InsertFile,
  InsertIframe,
  LimitIndent,
  SpecialCharactersEmoji,
];

export const editorConfiguration = {
  // https://ckeditor.com/docs/ckeditor5/latest/support/licensing/license-key-and-activation.html
  // https://ckeditor.com/docs/ckeditor5/latest/support/licensing/managing-ckeditor-logo.html
  // we use a development license key for local development and a production license key for production
  /*********************
   * LICENSE KEY *
   ********************/
  licenseKey: process.env.REACT_APP_CKEDITOR_LICENSE_KEY,
  /*********************
   * CONFIGURE PLUGINS *
   ********************/

  // configure Code Block plugin
  // https://ckeditor.com/docs/ckeditor5/latest/features/code-blocks.html
  codeBlock: {
    languages: [{language: 'plaintext', label: 'Code'}],
  },

  // configure font plugin
  // https://ckeditor.com/docs/ckeditor5/latest/features/font.html#configuring-the-font-color-and-font-background-color-features
  fontBackgroundColor: {
    colors: colors,
  },
  fontColor: {
    colors: colors,
  },

  // configure headings plugin
  // https://ckeditor.com/docs/ckeditor5/latest/features/headings.html#configuration
  heading: {
    options: editorHeadingOptions,
  },

  // configure image plugin
  // https://ckeditor.com/docs/ckeditor5/latest/features/images/images-overview.html
  image: {
    toolbar: [
      'toggleImageCaption',
      'imageTextAlternative',
      '|',
      'imageStyle:alignLeft',
      'imageStyle:alignBlockLeft',
      'imageStyle:alignCenter',
      'imageStyle:alignBlockRight',
      'imageStyle:alignRight',
      '|',
      'resizeImage:original',
      'resizeImage:75',
      'resizeImage:50',
    ],
  },

  // configure block indent plugin
  // https://ckeditor.com/docs/ckeditor5/latest/features/indent.html
  indentBlock: {
    offset: 2,
    unit: 'em',
  },

  // configure document list plugin
  // https://ckeditor.com/docs/ckeditor5/latest/features/lists/document-lists.html
  list: {
    properties: {
      styles: false,
      startIndex: true,
      reversed: true,
    },
  },

  // configure link plugin
  // https://ckeditor.com/docs/ckeditor5/latest/features/link.html
  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: 'https://',
  },

  // configure the table plugin
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
  },

  // this is just here to test the enhanced config
  testing: {
    id: 'testing',
    description: 'default testing config',
  },

  /**************
   * ADD PLUGINS *
   **************/

  // add plugins
  plugins: editorPlugins,

  /********************
   * CONFIGURE TOOLBAR *
   ********************/

  toolbar: editorToolbarOptions,

  /********************
   * ENHANCED FEATURES *
   * Due to the async nature of the extension release, we gate new editor features behind the CKEditorEnhanced feature flag.
   * Once the extension is released, we can turn on the flag to enable the features below.
   * Post-release, we need to refactor these settings into the standard config.
   *
   * NOTE: The enhanced config is MERGED into the default config via getEditorConfiguration.
   * If you want to override the config you can pass your config option in the EditingView component.
   ********************/
  enhanced: {
    config: {
      // this is just here to test the enhanced config
      testing: {
        description: 'enhanced testing config',
      },
    },
    plugins: [
      ...editorPlugins,
      // feature flagged plugins go here
      SpekitAI,
    ],
    toolbar: [
      // feature flagged toolbar options go here
      {
        label: 'Spekit AI',
        icon: sparklingIcon,
        items: ['aiSummarize', 'aiProofread', 'aiStylize', 'aiTranslate', 'aiRequest'],
      },
      '|',
      ...editorToolbarOptions,
    ],
  },
};

export const spotlightHeaderEditorConfig = {
  pasteFromWordRemoveFontStyles: true,
  plugins: [
    Strikethrough,
    Essentials,
    Italic,
    Paragraph,
    PasteFromOffice,
    Underline,
    WordCount,

    // custom Plugins
    SpotlightHeader,
  ],
  // plugins: [...spotlightPlugins, SpotlightHeader],
  toolbar: ['italic', 'underline', 'strikethrough'],
};

export const spotLightEditorConfig = {
  plugins: [
    // out of box plugins
    Autoformat,
    Font,
    SpecialCharacters,
    Strikethrough,
    Alignment,
    DocumentList,
    DocumentListProperties,
    Essentials,
    Indent,
    IndentBlock,
    Italic,
    Paragraph,
    PasteFromOffice,
    Underline,
    WordCount,
    Bold,

    // custom plugins
    DisallowNestingTables,
    LimitIndent,
    SpecialCharactersEmoji,
  ],
  toolbar: [
    'undo',
    '|',
    'fontColor',
    'fontBackgroundColor',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    '|',
    'bulletedList',
    'numberedList',
    'outdent',
    'indent',
    'alignment',
    '|',
    'specialCharacters',
  ],
};

// returns the editor configuration, merging in the enhanced config if enhanced is true
export const getEditorConfiguration = (enhanced?: boolean) => {
  const config = {
    ...editorConfiguration,
    plugins: enhanced
      ? editorConfiguration.enhanced.plugins
      : editorConfiguration.plugins,
    toolbar: enhanced
      ? editorConfiguration.enhanced.toolbar
      : editorConfiguration.toolbar,
  };

  // loop through entries in enhance.config and merge them into config
  if (enhanced) {
    Object.entries(editorConfiguration.enhanced.config).forEach(([key, value]) => {
      config[key] = {
        ...config[key],
        ...(value as object),
      };
    });
  }

  return config;
};
