import {ablyActionType} from './action_types';
import {FLAGS} from 'spekit-datalayer';

export function updateUser(user) {
  return {
    type: 'USERUPDATED',
    user: user,
  };
}
export function updateUserProfile(profileData) {
  return {
    type: 'USER_PROFILE_UPDATED',
    profileData,
  };
}
export function showPaywall(paywall, paywallType) {
  return {
    type: 'SHOWPAYWALL',
    paywall: paywall,
    paywallType: paywallType,
  };
}
export function clearPaywall() {
  return {
    type: 'CLEARPAYWALL',
  };
}

export function setRoles(roles) {
  return {
    type: 'SETROLES',
    roles: roles,
  };
}
export function addNotification(notification) {
  return {
    type: 'ADDNOTIFICATION',
    notification: notification,
  };
}
export function removeNotification(notification) {
  return {
    type: 'REMOVENOTIFICATION',
    notification: notification,
  };
}
export function setFieldTypes(fieldTypes) {
  return {
    type: 'SETFIELDTYPES',
    fieldTypes: fieldTypes,
  };
}
export function setExportJobId(exportJobId) {
  return {
    type: 'SETEXPORTJOBID',
    exportJobId: exportJobId,
  };
}
export function setImportJobId(importJobId) {
  return {
    type: 'SETIMPORTJOBID',
    importJobId: importJobId,
  };
}
export function updateWikiStoredState(storedState) {
  return {
    type: 'WIKISTOREDSTATEUPDATE',
    storedState: storedState,
  };
}
export function updateUnreadCount(count) {
  return {
    type: 'NOTIFICATIONSUPDATECOUNT',
    count: count,
  };
}
export function showTopicModal(term) {
  return {
    type: 'SHOWTOPICMODAL',
    term: term,
  };
}
export function closeTopicModal() {
  return {
    type: 'CLOSETOPICMODAL',
  };
}
export function setUploadBusy() {
  return {
    type: 'SETBUSY',
  };
}
export function unSetUploadBusy() {
  return {
    type: 'UNSETBUSY',
  };
}
export function topicReindexed(status, taskId) {
  return {
    type: 'TOPICREINDEXED',
    status,
    taskId,
  };
}
export function addIndexingTask(taskId, label) {
  return {
    type: 'ADDINDEXINGTASK',
    taskId,
    label,
  };
}

export function cloneSpek(termToClone, openCloneSpek) {
  return {
    type: 'CLONESPEK',
    termToClone,
    openCloneSpek,
  };
}

export function createSpekWithTopic(topic, topicId) {
  return {
    type: 'CREATE_SPEK_WITH_TOPIC',
    topic,
    topicId,
  };
}

export function getTeams(teams) {
  return {
    type: 'GET_TEAMS',
    teams: teams,
  };
}

export function getSpotlights(spotlights) {
  return {
    type: 'GET_SPOTLIGHTS',
    spotlights: spotlights,
  };
}

export function setUserStages(stages) {
  return {
    type: 'SET_USER_STAGES',
    stages: stages,
  };
}

export function showSpotlightModal(teams, message, fileDetails) {
  return {
    type: 'SHOW_SPOTLIGHT',
    teams,
    message,
    fileDetails,
  };
}

export function showSpotlightModalWithId(id) {
  return {
    type: 'SHOW_SPOTLIGHT_ID',
    payload: id,
  };
}

export function closeSpotlightModal() {
  return {
    type: 'CLOSE_SPOTLIGHT',
  };
}

export function createSpotlightWithKnowledgeCheck(knowledgeCheck) {
  return {
    type: 'CREATE_SPOTLIGHT_WITH_KNOWLEDGE_CHECK',
    payload: knowledgeCheck,
  };
}

export function createSpotlightWithFlow(flow) {
  return {
    type: 'CREATE_SPOTLIGHT_WITH_FLOW',
    payload: flow,
  };
}

export function createSpotlightWithFile(payload) {
  return {
    type: 'CREATE_SPOTLIGHT_WITH_FILE',
    payload,
  };
}

export function setFlags(features) {
  const payload = {};

  // creates the action payload of the form {hasXFlag: boolean}
  // this sets the non existent flags to false so that its easier
  // to check if the flag is enabled/disabled
  for (const flag in FLAGS) {
    if (FLAGS.hasOwnProperty(flag)) {
      const key = `has${flag}Flag`;
      payload[key] = features.includes(FLAGS[flag]);
    }
  }

  return {
    type: 'SET_FLAGS',
    payload,
  };
}
export function setSeismicStatus(payload) {
  return {
    type: 'SET_SEISMIC_STATUS',
    payload,
  };
}

export function showCreateSpekModal() {
  return {
    type: 'SHOW_CREATE_SPEK_MODAL',
  };
}

export function hideCreateSpekModal() {
  return {
    type: 'HIDE_CREATE_SPEK_MODAL',
  };
}
export function setAblyChannels(payload) {
  return {
    type: ablyActionType,
    payload,
  };
}

export function setAsyncSpotlightCreate(payload) {
  return {
    type: 'SET_ASYNC_SPOTLIGHT_CREATE',
    payload,
  };
}

export function setFeatures(features) {
  return {
    type: 'SET_FEATURES',
    features,
  };
}

export function showAssetUploadModal(payload = null) {
  return {
    type: 'SHOW_ASSET_UPLOAD_MODAL',
    payload,
  };
}
export function hideAssetUploadModal() {
  return {
    type: 'HIDE_ASSET_UPLOAD_MODAL',
  };
}

export function showEditableAssetModal(payload, editableFile) {
  return {
    type: 'SHOW_EDITABLE_ASSET_MODAL',
    editableAsset: payload,
    editableFile,
  };
}
export function setSearchResults(payload) {
  return {
    type: 'SET_SEARCH_RESULTS',
    payload,
  };
}

export function setSearchResultsLoader(payload) {
  return {
    type: 'SET_SEARCH_RESULTS_LOADER',
    payload,
  };
}

export function updateSearchResultsHidden(payload) {
  return {
    type: 'UPDATE_SAVED_RESULT_HIDDEN',
    payload,
  };
}

export function setSharingPrivacy(payload) {
  return {
    type: 'SET_SHARING_PRIVACY',
    payload,
  };
}

export function showContentShareModal(payload) {
  return {
    type: 'SHOW_CONTENT_SHARE_MODAL',
    payload,
  };
}
export function hideContentShareModal() {
  return {
    type: 'HIDE_CONTENT_SHARE_MODAL',
  };
}

export function setShareLinkData(payload) {
  return {
    type: 'SET_SHARE_LINK_DATA',
    payload,
  };
}

export function showContentImportModal(payload = null) {
  return {
    type: 'SHOW_CONTENT_IMPORT_MODAL',
    payload,
  };
}

export function hideContentImportModal() {
  return {
    type: 'HIDE_CONTENT_IMPORT_MODAL',
  };
}
