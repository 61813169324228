import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Avatar, Box, Flex, Icon, Text, DSButton as Button} from 'spekit-ui';
import {RiEdit2Line} from 'react-icons/ri';
import Support from '../../support';
import {RootState} from '../../reduxStore';
import {getBuildVersionEnv} from '../../utils/getEnv';

export default function Profile() {
  const me = useSelector((state: RootState) => state.layout.me);

  const name = `${me.first_name} ${me.last_name}`;
  const profileURL = me.user_profile?.profile_image
    ? `${window.location.origin}${me.user_profile?.profile_image}`
    : '';
  return (
    <Flex
      pt={52}
      direction='column'
      alignItems='center'
      justify='center'
      data-testid='profile-page'
    >
      <Box
        minWidth='600px'
        bg='neutral.0'
        border='1px solid'
        borderColor='neutral.200'
        borderRadius={4}
        p={60}
        textAlign='center'
        position='relative'
        boxSizing='border-box'
      >
        <Box position='absolute' top='23px' right='30px' data-testid='profile-edit-icon'>
          <Link to='/app/settings/profile/edit'>
            <Button
              size='medium'
              variant='icon'
              colorScheme='outlined'
              aria-label='Edit Profile'
              icon={<Icon as={RiEdit2Line} />}
            />{' '}
          </Link>
        </Box>

        <Avatar
          name={name}
          sx={{
            img: {
              objectFit: 'fill',
            },
          }}
          src={profileURL ?? undefined}
          size='2xl'
          bg={profileURL ? 'transparent' : undefined}
          data-testid='profile-avatar'
          objectFit='fill'
        />

        <Text
          fontSize='medium'
          fontWeight='semibold'
          mt={18}
          color='neutral.800'
          data-testid='user-full-name'
        >
          {name}
        </Text>

        <Text fontSize='small' color='neutral.800' data-testid='user-company-name'>
          {me.user_profile && me.user_profile.title
            ? me.user_profile.title + ' at ' + me.company.name
            : me.company.name}
        </Text>

        {me.user_profile && (
          <Text fontSize='small' color='neutral.800' data-testid='user-department'>
            {me.user_profile.department}
          </Text>
        )}

        <Text fontSize='small' mt={30} color='neutral.800' data-testid='user-email'>
          Email: {me.email}
        </Text>

        <Text fontSize='small' color='neutral.800' data-testid='user-username'>
          Username: {me.username}
        </Text>

        <Text fontSize='small' color='neutral.800' data-testid='user-build-version'>
          Build version: {getBuildVersionEnv()}
        </Text>
      </Box>
      <Box
        mt={18}
        fontSize='medium'
        color='neutral.800'
        maxWidth='645px'
        textAlign='center'
        data-testid='user-support-text'
      >
        {' '}
        <Support />{' '}
      </Box>
    </Flex>
  );
}
