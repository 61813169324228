import React from 'react';
import {RiAddLine} from 'react-icons/ri';
import {SpekitIcon, DriveIcon, SharepointIcon} from './Icons';
import {Flex, DSButton as Button, Icon, Text} from 'spekit-ui';

interface IProps {
  name: string;
}

const store = {
  SharePoint: <SharepointIcon fontSize='60px' />,
  'Google Drive': <DriveIcon fontSize='60px' />,
};

export const NoConnection = (props: IProps) => {
  const {name} = props;

  const host = process.env.REACT_APP_API_HOST || '';
  const redirectUrl = host + '/app/accounts';

  return (
    <>
      <Flex
        data-testid='no-connection'
        mt={24}
        direction='column'
        align='center'
        justify='center'
        gap={12}
      >
        <Flex gap={16} align='center'>
          {store[name]}
          <Icon color='neutral.600' fontSize={24} as={RiAddLine} />
          <SpekitIcon fontSize='60px' />
        </Flex>
        <Text
          variant='body1'
          fontWeight={600}
          color='neutral.800'
          data-testid='modal-heading'
        >
          Sync your {name} content into Spekit
        </Text>

        <Text maxW='457px' align='center' variant='body2' color='neutral.800'>
          Make the most out of your Spekit experience by connecting your organizations
          {` ${name}`}. Seamlessly sync your content into Spekit for all users to easily
          access just-in-time.
        </Text>
      </Flex>

      <Flex align='center' justify='center' mt={42}>
        <a href={redirectUrl} style={{textDecoration: 'none'}}>
          <Button size='medium' variant='contained' colorScheme='primary'>
            Connect {name}
          </Button>
        </a>
      </Flex>
    </>
  );
};
