export const styles = (overrideGlobalStyles = false) => ({
  global: {
    '#spekit-breadcrumb > ol:first-of-type': {
      padding: 0,
    },
    body: {
      fontFamily: null,
      /**
       * Chakra applies background and color in body globally which causes issues
       * when we load theme provider in 3rd party apps(jira/github/etc) and chakra
       * doesn't provide a way to override this behavior. That is why we are
       * intentionally giving them an incorrect value so they fallback to their
       * original values.
       * See difference here: https://www.loom.com/share/cfe3c8e9d84c48b58d4ad880d1a3e72b
       */
      background: overrideGlobalStyles ? 'incorrect_value' : undefined,
      color: overrideGlobalStyles ? 'incorrect_value' : undefined,
    },
    '.chakra-toast-portal': {
      display: 'initial !important',
    },
    '.chakra-portal': {
      display: 'initial !important',
    },
  },
});
