import {IProfilePictureFormData, IProfilePictureResponse} from 'spekit-types';
import {PROFILE_API} from '../api';
import {patchForm} from '../utils/APIHelpers';

export const stateToApi = (data: IProfilePictureFormData) => {
  const formData = new FormData();

  formData.append('title', data.title || '');
  formData.append('department', data.department || '');
  formData.append('user.first_name', data.user.first_name || '');
  formData.append('user.last_name', data.user.last_name || '');

  if (data.profile_image === null) {
    formData.append('profile_image', '');
  } else if (data.profile_image instanceof File) {
    formData.append('profile_image', data.profile_image);
  }

  return formData;
};

export const updateProfile = async (
  id: string,
  data: IProfilePictureFormData
): Promise<IProfilePictureResponse> => {
  const payload = stateToApi(data);
  return await patchForm(`${PROFILE_API}${id}/profile/`, payload);
};
