import React, {useState, useEffect, useRef} from 'react';
import {
  Steps,
  Creator,
  EngagementDetail,
} from 'spekit-datalayer/src/spotlights/spotlight.types';
import {
  Button,
  Flex,
  EmbeddedMediaPlayer,
  useWindowActiveTimer,
  Avatar,
  ImagesGallery,
  Box,
} from 'spekit-ui';
import {Spotlight} from 'spekit-datalayer';
import {StepDetails} from 'spekit-datalayer/src/flows/flows.types';
import {ReadOnlyView} from '../RichTextEditor/components';

const {getImage} = ImagesGallery;

const styles = {
  container: {
    backgroundColor: '#ffffff',
    position: 'relative',
  } as React.CSSProperties,
  contentDescription: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  } as React.CSSProperties,
  heading: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#56606A',
    marginTop: '10px',
    marginBottom: '15px',
    wordBreak: 'break-word',
    textAlign: 'left',
  } as React.CSSProperties,
  sentByUser: {
    color: '#333333',
    fontSize: '14px',
  },
  summary: {
    color: '#56606A',
    fontSize: '14px',
    wordBreak: 'break-word',
    textAlign: 'left',
  } as any,

  cover: {
    width: '100%',
    maxHeight: '240px',
    objectFit: 'contain',
  } as React.CSSProperties,
  body: {
    paddingTop: '5px',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  acknowledgeSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  terminalAcknowledge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  showMeLater: {
    marginRight: '10px',
    fontSize: '14px',
    color: '#637280',
    cursor: 'pointer',
  },
  actionButton: {
    width: '100px',
  },
  buttonsContainer: {
    padding: '20px 15px',
    boxSizing: 'border-box',
    position: 'sticky',
    bottom: '0',
    right: '0',
    left: '0',
    width: '100%',
    background: '#FFF',
  } as React.CSSProperties,
};

interface ViewWalkthroughProps {
  step: Steps | StepDetails;
  session?: string;
  onActionButton: (engagement: EngagementDetail) => void;
  onShowMelater?: (session: string | undefined) => void;
  host?: string;
  track?: any;
  creator: Creator;
  onEngagementDone?: () => void;
  terminal?: boolean;
  isHTML?: boolean;
  isHTMLHeader?: boolean;
  onDescriptionHeightChange?: (height: number) => void;
  onHeightChange?: (height: number) => void;
}

export default function ViewWalkthrough({
  step,
  onActionButton,
  onShowMelater,
  session,
  host,
  track,
  creator,
  onEngagementDone,
  terminal,
  isHTML,
  isHTMLHeader,
  onDescriptionHeightChange,
  onHeightChange,
}: ViewWalkthroughProps) {
  const [eventId, setEventId] = useState<string>('');
  const {getActiveTime} = useWindowActiveTimer();
  const [height, setHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const profileImageUrl = creator.profile_image
    ? `${window.location.origin}${creator.profile_image}`
    : '';

  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        setHeight(containerRef.current.offsetHeight);
      }
    };

    window.addEventListener('resize', updateHeight);
    updateHeight();

    // @ts-ignore
    const images = containerRef.current.querySelectorAll('img');
    // @ts-ignore
    images.forEach((image) => {
      image.addEventListener('load', updateHeight);
    });

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  useEffect(() => {
    if (onHeightChange) {
      onHeightChange(height);
    }
  }, [height]);

  useEffect(() => {
    let viewType = 'walkthrough_view';
    if (session) {
      let data = {session_id: session, view_type: 'view'};
      Spotlight.viewEvent(viewType, data).then((response) =>
        setEventId(response.event_id)
      );
    }
  }, [session]);

  const handleEngagement = async () => {
    if (step && step.engagements.length && session) {
      let response = await Spotlight.ack(step.engagements[0].id, session);
      track(`Walkthrough engagement backend response`, {
        walkthrough_id: step.engagements[0].id,
        response: response,
      });
      if (typeof onEngagementDone === 'function') {
        onEngagementDone();
      }
    }
    logView();
    onActionButton(step.engagements[0]);
  };

  const logView = () => {
    if (session) {
      let viewType = 'walkthrough_view';
      let viewSource = 'webapp';
      if (session) {
        viewSource = 'contextual-trigger';
      } else if (track) {
        viewSource = 'chrome-sidebar';
      }
      let data = {
        event_id: eventId,
        view_source: viewSource,
        view_type: 'view',
        view_on_url: window.location.href,
        time_spent: getActiveTime(),
      };
      Spotlight.viewEvent(viewType, data);
    }
  };

  const handleShowMeLater = () => {
    logView();
    onShowMelater!(session);
  };

  return (
    <div style={styles.container} ref={containerRef}>
      {step.embed_url ? (
        <EmbeddedMediaPlayer url={step.embed_url} />
      ) : (
        <img style={styles.cover} src={getImage(step, host)} />
      )}

      <div style={styles.body}>
        <Flex align='center' gap={6}>
          <Avatar
            name={`${creator.first_name} ${creator.last_name}`}
            size='xs'
            src={profileImageUrl || undefined}
          />
          <div style={styles.sentByUser}>
            <span> Sent by </span>
            <span>{creator ? `${creator.first_name} ${creator.last_name}` : ''}</span>
          </div>
        </Flex>

        <div style={styles.contentDescription}>
          <label style={styles.heading} data-testid='walkthrough-label'>
            {isHTMLHeader ? <ReadOnlyView value={step.label} /> : step.label}
          </label>
          <span style={styles.summary} data-testid='walkthrough-description'>
            {isHTML ? (
              <Box minH={120}>
                <ReadOnlyView
                  value={step.description}
                  onHeightChange={onDescriptionHeightChange}
                />
              </Box>
            ) : (
              step.description
            )}
          </span>
        </div>

        <div style={styles.buttonsContainer}>
          <div style={terminal ? styles.terminalAcknowledge : styles.acknowledgeSection}>
            {!terminal && onShowMelater && (
              <label style={styles.showMeLater} onClick={handleShowMeLater}>
                {' '}
                Show me later
              </label>
            )}
            <Button primary onClick={handleEngagement} style={styles.actionButton}>
              {' '}
              {step?.engagements?.length ? step.engagements[0].label : 'Got it'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
