import React from 'react';
import {IDrive} from 'spekit-types';
import {Flex, SimpleGrid, Spinner, Text} from 'spekit-ui';
import {DriveCard} from './DriveCard';

interface IProps {
  selectedDrive?: IDrive;
  isLoading?: boolean;
  drives?: IDrive[];
  onClick: (drive: IDrive) => void;
}

export const DriveSelector = ({
  drives = [],
  onClick,
  selectedDrive,
  isLoading,
}: IProps) => {
  if (isLoading) {
    return (
      <Flex mt={10} align='center' justify='center' direction='column' gap={4}>
        <Spinner
          color='primary.500'
          emptyColor='neutral.200'
          size='md'
          speed='1.65s'
          thickness='4px'
          data-testid='drive-spinner'
        />
        <Text variant='body2' color='neutral.500'>
          Loading...
        </Text>
      </Flex>
    );
  }

  return (
    <Flex mt={24} mb={32} direction='column' flex='1'>
      {drives.length === 0 && (
        <Text variant='caption1' fontWeight={400} mt={4} color='neutral.600'>
          No drives available to sync. Check your connection settings and try again.
        </Text>
      )}

      {drives.length > 0 && (
        <SimpleGrid columns={2} spacing={10}>
          {drives.map((drive) => (
            <DriveCard
              key={drive.id}
              drive={drive}
              onClick={onClick}
              isSelected={selectedDrive?.id === drive.id}
            />
          ))}
        </SimpleGrid>
      )}
    </Flex>
  );
};
