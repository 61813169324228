import {useQueries} from '@tanstack/react-query';
import {useIntegrationApp, Connection} from '@integration-app/react';
import {IContentStore, TIntegrationKeys} from '../constants';
import {logging} from 'spekit-datalayer';

export const useConnections = (hasContentIntegrationSharepointFlag?: boolean) => {
  const integrationApp = useIntegrationApp();

  const stores: IContentStore[] = [
    {
      connectionKey: 'gdrive',
      label: 'Google Drive',
    },
  ];

  if (hasContentIntegrationSharepointFlag) {
    stores.push({
      connectionKey: 'microsoft-sharepoint',
      label: 'SharePoint',
    });
  }

  const contentStoreStatus = useQueries({
    queries: stores.map((store) => ({
      queryKey: ['store', store.connectionKey],
      queryFn: async () => {
        const response = await integrationApp.integration(store.connectionKey).get();
        return response.connection || false;
      },
      retry: false,
      onError: logging.capture,
    })),
  });

  const connections = {};

  stores.forEach((store, index) => {
    connections[store.connectionKey] = contentStoreStatus[index].data;
  });

  return {
    connections: connections as Record<TIntegrationKeys, Connection>,
    stores,
  };
};
