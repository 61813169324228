import React from 'react';
import {ChakraProvider} from '@chakra-ui/react';
import {updateTheme} from './theme';
import {Toaster} from 'react-hot-toast';

export interface IThemeProvider {
  children: React.ReactNode;
  portalZIndex?: number;
  resetCSS?: boolean;
  primaryColor?: string | null;
  overrideGlobalStyles?: boolean;
}

export function ThemeProvider(props: IThemeProvider) {
  const {children, resetCSS = false, primaryColor, overrideGlobalStyles, ...rest} = props;

  React.useLayoutEffect(() => {
    if (!overrideGlobalStyles) return;
    const cached: Record<string, string> = {};
    const cacheHtmlAttributes = () => {
      const htmlElement = document.querySelector('html');
      const attributes = htmlElement?.attributes;
      const attributesLength = attributes?.length || 0;
      for (let i = 0; i < attributesLength; i += 1) {
        const attr = attributes![i];
        cached[attr.name] = attr.value;
      }
    };
    cacheHtmlAttributes();
    requestAnimationFrame(() => {
      const htmlElement = document.querySelector('html');
      Object.keys(cached).forEach((key) => {
        htmlElement?.setAttribute(key, cached[key]);
      });
    });
  }, [overrideGlobalStyles]);

  return (
    <ChakraProvider
      {...rest}
      theme={updateTheme(primaryColor, overrideGlobalStyles)}
      resetCSS={resetCSS}
    >
      {children}
      <Toaster position='bottom-left' containerStyle={{zIndex: 2147483647}} />
    </ChakraProvider>
  );
}
