import React from 'react';
import {Icon, TIconProps} from '../design-system/Icon';

export const PdfIcon = (props: TIconProps) => (
  <Icon
    viewBox='0 0 67 86'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    data-testid='pdf-icon'
    {...props}
  >
    <path
      d='M56.064 19.6003C53.887 19.5992 51.7995 18.7343 50.2598 17.1953C48.7201 15.6564 47.8541 13.5693 47.852 11.3924V0H11.1792C9.77217 -9.71497e-08 8.37895 0.277181 7.07909 0.815743C5.77923 1.3543 4.59821 2.14368 3.60348 3.13877C2.60876 4.13387 1.8198 5.31519 1.28172 6.61525C0.743639 7.91531 0.467007 9.30867 0.46753 10.7157V75.2843C0.467007 76.6913 0.743639 78.0846 1.28172 79.3847C1.8198 80.6848 2.60876 81.8661 3.60348 82.8612C4.59821 83.8563 5.77923 84.6457 7.07909 85.1842C8.37895 85.7228 9.77217 86 11.1792 86H55.2042C56.6112 86 58.0044 85.7228 59.3043 85.1842C60.6042 84.6457 61.7852 83.8563 62.7799 82.8612C63.7746 81.8661 64.5635 80.6848 65.1016 79.3847C65.6396 78.0846 65.9164 76.6913 65.9158 75.2843V19.6043L56.064 19.6003Z'
      fill='#FFF3F6'
    />
    <path
      d='M65.9158 19.6003H56.064C53.887 19.5992 51.7995 18.7343 50.2598 17.1953C48.7201 15.6564 47.8541 13.5693 47.852 11.3924V0L65.9158 19.6003Z'
      fill='#FF006B'
    />
    <path
      d='M29.3098 31C29.3098 35.3689 28.0603 40.5198 25.9509 45.2726C23.8339 50.0426 20.9783 54.1263 18 56.5327L21.2339 61C29.261 55.5947 38.1483 51.8882 46.745 53.1287L48 47.7594C40.6715 45.292 34.7938 37.896 34.7938 31H29.3098ZM30.955 47.5386C31.6882 45.8869 32.3378 44.1728 32.8848 42.4341C34.1779 44.4409 35.7683 46.2829 37.5637 47.8812C34.8716 48.3681 32.2344 49.1671 29.6808 50.1863C30.1324 49.3186 30.5579 48.4337 30.955 47.5386Z'
      fill='#FF006B'
    />
  </Icon>
);
