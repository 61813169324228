import {AxiosResponse} from 'axios';
import {api, CONTENT_INTEGRATIONS} from '../api';

export const generateToken = async () => {
  return api
    .post(`${CONTENT_INTEGRATIONS}import/generate_token/`)
    .then((response) => response.data);
};

export const importAssets = async (payload: {
  file_store_ids: string[];
  connection_key: string;
  tag_ids: string[];
  shareable?: boolean;
}) => {
  return api
    .post<Record<string, string>>(`${CONTENT_INTEGRATIONS}import/`, payload)
    .then((response) => response.data);
};
