import React from 'react';
import {
  RiBarChart2Line,
  RiFunctionLine,
  RiHome4Line,
  RiSettings3Line,
} from 'react-icons/ri';
import {useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {Box, Flex, NavigationButton} from 'spekit-ui';
import {RootState} from '../../reduxStore';
import {hasSomeOf} from '../../utils/permissions';
import {MoreButton} from './MoreButton';

export const AppNavigationButtons = () => {
  const location = useLocation();
  const session = useSelector((state: RootState) => state.layout.me);
  const {hasSpekLinkGenerationFlag} = useSelector((state: RootState) => state.flag);
  const isViewer = session.role === 'Viewer';
  const hasAnalyticsPermissions = !isViewer || hasSpekLinkGenerationFlag;
  const hasSettingsPermissions = hasSomeOf([
    'customcolumns.manage_custom_columns',
    'subscriptiondetails.view_own',
    'salesforceobjects.sync_own',
    'userprofiles.add_own',
    'userprofiles.update_own',
    'salesforceorganizations.view_export_own',
    'salesforceorganizations.view_upload_own',
  ]);

  const routes = {
    home: '/app/dashboard',
    wiki: '/app/wiki/topics',
    analytics: '/app/analytics/adoption-dashboard',
    settings: '/app/settings/access',
  };

  return (
    <Flex alignItems='center' gap={20}>
      <Box display={{base: 'none', 'tablet-md': 'revert'}}>
        <Link to={routes.home} style={{textDecoration: 'none'}}>
          <NavigationButton
            icon={RiHome4Line}
            isActive={location.pathname.includes('/app/dashboard')}
            data-testid='nav-item-home'
          >
            Home
          </NavigationButton>
        </Link>
      </Box>
      <Box display={{base: 'none', 'tablet-lg': 'revert'}}>
        <Link to={routes.wiki} style={{textDecoration: 'none'}}>
          <NavigationButton
            icon={RiFunctionLine}
            isActive={location.pathname.includes('/app/wiki')}
            data-testid='nav-item-wiki'
          >
            Wiki
          </NavigationButton>
        </Link>
      </Box>

      {hasAnalyticsPermissions && (
        <Box display={{base: 'none', 'desktop-sm': 'revert'}}>
          <Link to={routes.analytics} style={{textDecoration: 'none'}}>
            <NavigationButton
              icon={RiBarChart2Line}
              isActive={location.pathname.includes('/app/analytics')}
              data-testid='nav-item-analytics'
            >
              Analytics
            </NavigationButton>
          </Link>
        </Box>
      )}
      {hasSettingsPermissions && (
        <Box display={{base: 'none', 'desktop-sm': 'revert'}}>
          <Link to={routes.settings} style={{textDecoration: 'none'}}>
            <NavigationButton
              icon={RiSettings3Line}
              isActive={location.pathname.includes('/app/settings')}
              data-testid='nav-item-settings'
            >
              Settings
            </NavigationButton>
          </Link>
        </Box>
      )}

      <MoreButton
        routes={routes}
        hasAnalyticsPermissions={hasAnalyticsPermissions}
        hasSettingsPermissions={hasSettingsPermissions}
      />
    </Flex>
  );
};
