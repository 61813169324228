import React from 'react';
import {RiHardDrive2Line} from 'react-icons/ri';
import {IDrive} from 'spekit-types';
import {Flex, Icon, Text} from 'spekit-ui';

interface IProps {
  drive: IDrive;
  onClick: (drive: IDrive) => void;
  isSelected?: boolean;
}

export const DriveCard = ({drive, isSelected, onClick}: IProps) => {
  const handleClick = () => {
    onClick(drive);
  };

  let border = {
    borderWidth: 1,
    borderColor: 'neutral.200',
    borderStyle: 'solid',
  };
  if (isSelected) {
    border = {
      borderWidth: 2,
      borderColor: 'primary.500',
      borderStyle: 'solid',
    };
  }

  return (
    <Flex
      my='1px'
      gap={10}
      boxSizing='border-box'
      borderRadius={8}
      boxShadow='md'
      cursor='pointer'
      data-testid={`${drive.name}-drive`}
      onClick={handleClick}
      p={26}
      {...border}
      _hover={{
        transition: 'all 0.2s',
        shadow: 'md',
      }}
    >
      <Icon fontSize={24} as={RiHardDrive2Line} />
      <Text fontWeight='600' variant='body1'>
        {drive.name}
      </Text>
    </Flex>
  );
};
