import React, {useState} from 'react';
import {IDrive} from 'spekit-types';
import {ModalBody, Tab, TabList, TabPanels, Tabs} from 'spekit-ui';
import {TIntegrationKeys} from '../constants';
import {TabPanel} from '@chakra-ui/tabs';
import {DriveSelector} from './DriveSelector';
import {NoConnection} from './NoConnection';
import {useDrives} from '../hooks/useDrives';

interface IProps {
  selectedDrive?: IDrive;
  onDriveSelect: (store: TIntegrationKeys, drive: IDrive) => void;
  onStoreClick: (store: TIntegrationKeys) => void;
  hasContentIntegrationSharepointFlag?: boolean;
  setActiveTab: (activeTab: number) => void;
  activeTab: number;
}

export const ContentStores = (props: IProps) => {
  const {
    selectedDrive,
    onDriveSelect,
    onStoreClick,
    hasContentIntegrationSharepointFlag,
    setActiveTab,
    activeTab,
  } = props;

  const contentStoreDrives = useDrives(hasContentIntegrationSharepointFlag);

  return (
    <ModalBody my={24} overflow='visible'>
      <Tabs
        data-testid='content-stores'
        onChange={(index) => {
          onStoreClick(contentStoreDrives[index].store.connectionKey);
          setActiveTab(index);
        }}
        index={activeTab}
      >
        <TabList>
          {contentStoreDrives.map(({store}) => (
            <Tab data-testid={`${store.connectionKey}-tab`} key={store.connectionKey}>
              {store.label}
            </Tab>
          ))}
        </TabList>

        <TabPanels p={0}>
          {contentStoreDrives.map(({drives, store, connection}) => (
            <TabPanel key={store.connectionKey}>
              {connection && !connection.disconnected ? (
                <DriveSelector
                  selectedDrive={selectedDrive}
                  isLoading={drives.isLoading}
                  drives={drives.data || []}
                  onClick={(drive) => onDriveSelect(store.connectionKey, drive)}
                />
              ) : (
                ((drives.fetchStatus !== 'fetching' && !drives.isLoading) ||
                  (connection as unknown as boolean) === false) && (
                  <NoConnection name={store.label} />
                )
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </ModalBody>
  );
};
