import React, {useEffect, useState} from 'react';
import {useCustomFields} from '../../hooks';
import {
  TCustomFieldContentTypes,
  TComputedValues,
  TComputedValue,
  TCustomFieldValues,
  TCustomFieldValue,
  TTermType,
  TCustomField,
} from 'spekit-types';
import {Flex, FormLabel, Text, Skeleton} from 'spekit-ui';
import {transformFieldValuesToComputedValues, utils} from 'spekit-datalayer';
import {FieldsHandler} from './FieldHandler';
const {capitalizeFirstAndLowercaseRest, assertExhaustive} = utils;

interface CustomFieldManagerBase {
  type: TTermType | 'file';
  values: TCustomFieldValues;
}

interface IView extends CustomFieldManagerBase {
  mode?: 'view';
}

interface ICreateOrEdit extends CustomFieldManagerBase {
  mode?: 'create' | 'edit' | 'clone';
  updateValues: (values: TCustomFieldValues) => void;
}

type CustomFieldEditor = IView | ICreateOrEdit;

const readableValue = (value: TComputedValue, field: TCustomField): string => {
  const {data_type} = field;
  switch (data_type) {
    case 'string':
      return String(value);
    case 'multi-option':
      if (Array.isArray(value)) {
        return value.map((option) => option.label).join(', ');
      }
      return '';
    default:
      return assertExhaustive(data_type);
  }
};
const validateValue = (value: TCustomFieldValue) =>
  value && (typeof value === 'string' || (Array.isArray(value) && value.length > 0));

export const CustomFieldsEditor = (props: CustomFieldEditor) => {
  const {type, values, mode = 'create'} = props;
  const pluralType = (type + 's') as TCustomFieldContentTypes;
  const {data, isLoading} = useCustomFields();

  const customFields = data?.filter((f) => f.on.includes(pluralType)) || [];
  const [computedValues, setComputedValues] = useState<TComputedValues | null>(null);

  useEffect(() => {
    if (!isLoading && computedValues === null) {
      if (mode === 'create') setComputedValues({});
      else setComputedValues(transformFieldValuesToComputedValues(values, customFields));
    }
  }, [isLoading, computedValues, values, customFields, mode, setComputedValues]);

  // in read only mode we only care for custom fields with values.
  const filteredFields =
    mode === 'view'
      ? customFields.filter((field) => validateValue(values[field.id]))
      : customFields;

  if (filteredFields.length === 0 && !isLoading) return null;

  const handleUpdate = (id: string, value: TComputedValue) => {
    if (!('updateValues' in props)) return;
    const {updateValues} = props;
    if (Array.isArray(value)) updateValues({...values, [id]: value.map((v) => v.value)});
    else updateValues({...values, [id]: value});
    setComputedValues({...computedValues, [id]: value});
  };

  return (
    <Flex
      direction='column'
      gap='12'
      w='100%'
      data-testid='custom-field-container'
      // we want to recreate this component if it changes from read to edit to create to
      // prefill correct data. (This is due to the way wiki.js implements edit)
      key={mode}
    >
      {mode !== 'view' && <Text fontWeight='semibold'>Custom fields</Text>}

      {isLoading || computedValues === null ? (
        <Flex direction='column' gap={20} data-testid='loader'>
          {Array.from({length: 2}).map((_, index) => (
            <Flex gap={6} direction='column' key={index}>
              <Skeleton height='20px' width='35%' borderRadius={6} />
              <Skeleton height='40px' borderRadius={6} />
            </Flex>
          ))}
        </Flex>
      ) : (
        <Flex data-testid='custom-field-data' gap={12} direction='column'>
          {filteredFields.map((field) => {
            const {id, name} = field;
            const value = computedValues[id];
            return (
              <Flex gap={6} direction='column' key={id} data-testid='custom-field'>
                <FormLabel m={0} fontWeight={mode === 'view' ? 'semibold' : 'normal'}>
                  {capitalizeFirstAndLowercaseRest(name)}
                </FormLabel>
                {mode === 'view' ? (
                  <Text
                    variant='body2'
                    data-testid='custom-field-value'
                    wordBreak='break-all'
                    whiteSpace='normal'
                  >
                    {readableValue(value, field)}
                  </Text>
                ) : (
                  <FieldsHandler
                    field={field}
                    value={value}
                    handleUpdate={handleUpdate}
                  />
                )}
              </Flex>
            );
          })}
        </Flex>
      )}
    </Flex>
  );
};
