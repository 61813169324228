import React from 'react';
import {
  Switch as ChakraSwitch,
  SwitchProps,
  ComponentStyleConfig,
  cssVar,
} from '@chakra-ui/react';

export type TSwitchProps = SwitchProps;

const $width = cssVar('switch-track-width');
const $height = cssVar('switch-track-height');

export const SwitchConfig: ComponentStyleConfig = {
  parts: ['container', 'track', 'thumb'],
  baseStyle: ({theme}) => {
    const {colors} = theme;
    return {
      track: {
        bg: 'neutral.400',
        _focus: {
          boxShadow: `0px 0px 0px 4px ${colors.primary['100']}`,
        },
        _checked: {backgroundColor: 'primary.500'},
        _disabled: {backgroundColor: 'primary.200'},
      },
    };
  },
  variants: {},
  sizes: {
    sm: {
      container: {[$width.variable]: '22px', [$height.variable]: '11px'},
    },
    md: {
      container: {[$width.variable]: '32px', [$height.variable]: '16px'},
    },
    lg: {
      container: {[$width.variable]: '49px', [$height.variable]: '24px'},
      track: {p: '3px'},
    },
  },
  defaultProps: {size: 'md'},
};

export const Switch = (props: TSwitchProps) => <ChakraSwitch {...props} />;
