import React from 'react';
//@ts-ignore
import getVideoId from 'get-video-id';

interface VideoPlayerProps {
  url: string;
  styles?: React.CSSProperties;
}

const computeStyles = (url: string, styles?: React.CSSProperties) => {
  const height = styles?.height || (url.includes('wistia') ? '265px' : '240px');
  const width = styles?.width || '100%';
  return {
    width,
    height,
  };
};

export default function Video(props: VideoPlayerProps) {
  const {styles, url} = props;
  const computedStyles = computeStyles(url, styles);
  const getURL = (URL: string) => {
    let url = URL;
    const videoInfo = getVideoId(URL);

    if (videoInfo.service === 'youtube') {
      url = `https://www.youtube-nocookie.com/embed/${videoInfo.id}?rel=0`;
    }
    return url;
  };

  //console.log('-->', videoInfo);
  const isOneDrive = url.includes('https://onedrive');
  return isOneDrive ? (
    <video id='oneDriveVideo' preload='none' style={computedStyles} controls>
      <source src={url} type='video/mp4'></source>
    </video>
  ) : (
    <iframe
      style={computedStyles}
      src={getURL(url)}
      allowFullScreen
      frameBorder='0'
    ></iframe>
  );
}
