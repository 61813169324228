import {useQueries, useQuery, UseQueryResult} from '@tanstack/react-query';
import {useIntegrationApp, Connection} from '@integration-app/react';
import {useConnections} from './useConnections';
import {IDrive} from 'spekit-types';
import {logging} from 'spekit-datalayer';
import {INTEGRATION_KEY_GOOGLE, INTEGRATION_KEY_SHAREPOINT} from '../constants';

interface IMSSite {
  createdDateTime: string;
  id: string;
  lastModifiedDateTime: string;
  name: string;
  webUrl: string;
  displayName: string;
  root: {};
  siteCollection: {
    hostname: string;
  };
}

const filterSites = (data: IMSSite[]) =>
  data.filter(
    (site: IMSSite) =>
      !(site.webUrl.includes('portals') || site.webUrl.includes('appcatalog'))
  );

const useSites = (hasContentIntegrationSharepointFlag?: boolean) => {
  const integrationApp = useIntegrationApp();

  const sites = useQuery({
    queryKey: ['sites'],
    staleTime: 5 * 60 * 1000,
    queryFn: async () => {
      const response = await integrationApp
        .connection(INTEGRATION_KEY_SHAREPOINT)
        .action('list-sites')
        .run();
      return response.output.records.map((result: any) => result.fields) as IMSSite[];
    },
    onError: logging.capture,
    enabled: hasContentIntegrationSharepointFlag,
    select: filterSites,
  });

  return {sites: sites.data || [], isLoading: sites.isLoading, isError: sites.isError};
};

const useSharepointDrives = (
  connection: Connection,
  hasContentIntegrationSharepointFlag?: boolean
) => {
  const integrationApp = useIntegrationApp();

  const {sites, isLoading, isError} = useSites(hasContentIntegrationSharepointFlag);

  const drives = useQueries({
    queries: sites.map((site) => {
      return {
        retry: false,
        staleTime: 5 * 60 * 1000,
        queryKey: ['drives', INTEGRATION_KEY_SHAREPOINT, site.id],
        queryFn: async () => {
          const response = await integrationApp
            .connection(INTEGRATION_KEY_SHAREPOINT)
            .action('list-drives')
            .run({siteId: site.id});
          return response.output.records.map((result: any) => ({
            ...result.fields,
            name: site.displayName + ' - ' + result.fields.name,
          })) as IDrive[];
        },
        enabled: Boolean(connection && !connection.disconnected && !isLoading && site.id),
        onError: logging.capture,
      };
    }),
  });

  return {
    data: drives.flatMap((drive) => drive.data || []),
    isLoading: drives.some((drive) => drive.isLoading) || isLoading,
    isError: drives.some((drive) => drive.isError) || isError,
  };
};

const useGoogleDrives = (connection: Connection) => {
  const integrationApp = useIntegrationApp();

  const drives = useQuery({
    staleTime: 5 * 60 * 1000,
    queryKey: ['drives', INTEGRATION_KEY_GOOGLE],
    queryFn: async () => {
      const response = await integrationApp
        .connection(INTEGRATION_KEY_GOOGLE)
        .action('list-drives')
        .run();
      return response.output.records.map((result: any) => result.fields) as IDrive[];
    },
    enabled: Boolean(connection && !connection.disconnected),
    onError: logging.capture,
  });

  return drives;
};

export const useDrives = (hasContentIntegrationSharepointFlag?: boolean) => {
  const {connections, stores} = useConnections(hasContentIntegrationSharepointFlag);

  const googleDrives = useGoogleDrives(connections[INTEGRATION_KEY_GOOGLE]);

  const sharepointDrives = useSharepointDrives(
    connections[INTEGRATION_KEY_SHAREPOINT],
    hasContentIntegrationSharepointFlag
  );

  const drives = [
    {
      drives: googleDrives,
      store: stores[0],
      connection: connections[INTEGRATION_KEY_GOOGLE],
    },
  ];

  if (hasContentIntegrationSharepointFlag) {
    drives.push({
      drives: sharepointDrives as UseQueryResult<IDrive[], any>,
      store: stores[1],
      connection: connections[INTEGRATION_KEY_SHAREPOINT],
    });
  }

  return drives;
};
