import React from 'react';
import {Icon} from 'spekit-ui';
import {RiArrowDownSLine, RiArrowRightSLine} from 'react-icons/ri';

export const ArrowIcon = ({isOpen}: {isOpen: boolean}) => {
  if (isOpen) {
    return <Icon fontSize={16} as={RiArrowDownSLine} />;
  }
  return <Icon fontSize={16} as={RiArrowRightSLine} />;
};
