import React from 'react';
import {PropagateLoader} from 'react-spinners';
import {getAssetExtension, utils} from 'spekit-datalayer';
import {IRecentlyViewedItem} from 'spekit-types';
import {useRecentlyViewed} from '../hooks';
import {useHistory} from 'react-router-dom';
import {Box, Divider, Flex, Icon, ICONS, Text} from 'spekit-ui';
import {colors} from 'spekit-ui/src/design-system/theme';

interface ISuggestedItemProps extends IRecentlyViewedItem {
  isFromExtension?: boolean;
}

export const SuggestedItem = ({
  id,
  label,
  type,
  content_type,
  isFromExtension,
}: ISuggestedItemProps) => {
  const icon = content_type ? ICONS[getAssetExtension(content_type)] : ICONS['spek'];
  const history = useHistory();

  const params = utils.stringifyQs(
    {
      expanded: true,
      type,
    },
    {addQueryPrefix: true}
  );

  const contentURL = isFromExtension
    ? `/term/${type}/${id}`
    : `/app/wiki/${type}/${id}${params}`;

  return (
    <Flex
      py={6}
      px={16}
      gap={6}
      align='center'
      cursor='pointer'
      _hover={{bgColor: 'neutral.50'}}
      onClick={() => history.push(contentURL)}
    >
      <Icon as={icon} color='neutral.800' w={4} h={4} data-testid={type} />
      <Text variant='body2' textOverflow='ellipsis' overflow='hidden' whiteSpace='nowrap'>
        {label}
      </Text>
    </Flex>
  );
};

interface ISuggestionsProps {
  aiAssistQuestion?: string;
  handleAIAssist?: () => void;
}

export const Suggestions = ({aiAssistQuestion, handleAIAssist}: ISuggestionsProps) => {
  const {isLoading, data: recentlyViewedContent} = useRecentlyViewed();
  const isFromExtension = utils.getEnvironment() !== 'webapp';

  const loader = (
    <Flex justify='center' mt={-4} data-testid='recent-content-loader'>
      <PropagateLoader loading={true} size={10} color={colors.primary[500]} />
    </Flex>
  );

  return (
    <Box
      py={16}
      w='100%'
      top={38}
      left={0}
      zIndex={1000}
      boxShadow='lg'
      bgColor='white'
      borderRadius={8}
      border='1px solid'
      color='neutral.800'
      position='absolute'
      boxSizing='border-box'
      borderColor='neutral.200'
    >
      <>
        {aiAssistQuestion && handleAIAssist && (
          <>
            <Flex
              px={16}
              py={6}
              gap={6}
              cursor='pointer'
              _hover={{bgColor: 'neutral.50'}}
              onClick={handleAIAssist}
            >
              <Icon
                as={ICONS['aiAssist']}
                color='neutral.800'
                w={4}
                h={4}
                data-testid={'ai-assist-search-suggestion'}
                flexShrink={0}
                marginTop={4}
              />
              <Text variant='body2'>
                Search for <strong>"{aiAssistQuestion}"</strong> using AI Assist
              </Text>
            </Flex>
            <Divider boxSizing='border-box' mt={12} mb={6} mx={16} />
          </>
        )}
        {isLoading ? (
          loader
        ) : (
          <>
            <Text variant='caption1' ps={16} pb={4} fontWeight={600} mt={10}>
              RECENTLY VIEWED
            </Text>
            {recentlyViewedContent?.map((item) => (
              <SuggestedItem key={item.id} {...item} isFromExtension={isFromExtension} />
            ))}
          </>
        )}
      </>
    </Box>
  );
};
